@import "mixins.scss";

.contentWrapper {
    width: 100%;
    max-width: 1350px;
    margin: 0 auto;
    padding: 0 10px 10px 10px;

    @include md {
        padding: unset !important;
    }
}

// =================== Top Bar ==========================
.topBar {
    height: 40px;
    background-color: rgb(27, 32, 44);
    border-bottom: 1px solid rgb(110 119 137);
    padding: 7px;
    display: none;

    @include sm {
        display: block;
    }
}

.wrapper-top-bar {
    display: flex;
    justify-content: space-between;
    color: var(--white);

    .leftTopBar {
        display: flex;
        gap: 10px;

        .callNow {
            margin-left: unset !important;
        }

        .displayNumber {
            font-size: 15px;
            margin-top: 2px;
            color: var(--white);
            text-decoration: none;

            .userIcons {
                color: var(--orange);
            }
        }

        .edTest {
            font-size: 15px;
            margin-top: 2px;
            color: var(--white);
            text-decoration: none;
        }
    }

    .rightTopBar {
        a {
            color: var(--white);
            text-decoration: none;
            font-size: 15px;

            .userIcons {
                color: var(--orange);
                margin-top: -5px;
                margin-right: 5px;
            }
        }

        .talkToDoctor {
            margin-top: 2px;
            margin-right: 20px;
        }

        .userLogin {
            margin-top: 2px;
            margin-right: 8px;
        }

        .help {
            margin-top: 2px;
        }

        .helpIcons {
            margin-top: 0px;
            margin-left: 3px;
            margin-right: 5px;
            color: var(--orange);
        }
    }
}

// =================== End Top Bar ======================
// =================== Start Mega Menu ====================
.dMegaMenu {
    background: var(--white);
    // height: 100px;

    &.top {
        background-color: var(--white);
    }

    &.show {
        background-color: var(--white);
        backdrop-filter: blur(25px);
        -webkit-backdrop-filter: blur(3.5px);
        position: fixed;
        width: 100%;
        z-index: 999;
        top: 0px;
        box-shadow: 0 2px 6px 0 rgba(218, 225, 255, 0.85), 1px -6px 8px 0 rgba(214, 188, 238, 0.95);
    }

    &.hide {
        transform: translateY(-60px);
    }
}

.MegaMenu {
    display: flex;
    justify-content: space-between;
    color: var(--black);
    padding: 10px 0px 10px 0px;

    .menuLink {
        width: 70%;
        display: none;

        ul {
            display: flex;
            justify-content: space-evenly;
            font-size: 14px;
            font-weight: 400;
            margin-top: 30px;
            letter-spacing: .6px;

            li {
                list-style: none;

                a {
                    text-decoration: none;
                    color: var(--black);
                    text-transform: uppercase;
                    letter-spacing: 0.8px;
                    font-weight: 600;

                    &:hover {
                        color: var(--orange);
                    }
                }
            }
        }

        @include md {
            display: block;
        }
    }

    .topCartCounter {
        color: #fff;
        display: flex !important;
        justify-content: right !important;
        gap: 27px;
        margin-top: 20px;
        cursor: pointer;

        .yourCartCounter {
            display: none;

            .cartIcons {
                font-size: 43px;
                padding: 8px;
                border: 1px solid var(--orange);
                color: var(--black);
                border-radius: 54px;
                margin-top: 3px;
            }

            .cartCounter {
                font-size: 19px;
                position: absolute;
                color: #ffffff;
                margin-left: -7px;
                background: var(--orange);
                border-radius: 15px;
                width: 30px;
                text-align: center;
            }

            @include md {
                display: block !important;
            }
        }

        .yourCart {
            font-weight: 600;
            margin-left: 10px;
            display: none;
            font-size: 10px;

            .textCart {
                color: var(--orange);
            }

            .totalPriceCart {
                color: var(--orange);
            }

            @include md {
                display: block !important;
            }
        }
    }

    .mobileMenuItems {
        display: block;

        @include md {
            display: none;
        }

        .mobileMenuIcons {
            font-size: 20px;
            margin-top: 30px;
        }

        .mobileMenuIcon {
            display: none;
        }
    }

    .mobileMenu {
        display: block;
        height: 100vh;
        background: #fff;
        left: 0;
        top: 0;
        width: 250px;
        line-height: 35px;
        position: fixed;
        z-index: 999;

        ul {
            padding-left: unset;
            line-height: 40px;

            li {
                list-style: none;
                border-bottom: 1px solid #e6e3ed;
            }

            a {
                text-decoration: none;
                color: var(--black);
                font-weight: 400;
                letter-spacing: 0.5px;
                margin-left: 25px;

                .menuMobileIcons {
                    margin-right: 10px;
                    color: var(--orange);
                }
            }

            &:hover {
                color: var(--orange) !important;
            }
        }

        .mobileTopBar {
            margin-left: 25px;
            display: flex;
            justify-content: space-between;

            .yourCartCounter {
                .cartIcons {
                    font-size: 20px;
                    background: var(--orange);
                    color: #fff;
                    width: 30px;
                    border-radius: 5px;
                }

                .cartCounter {
                    font-size: 20px;
                    color: var(--orange);
                    margin-left: 5px;
                }
            }

            .mobileCloseBtn {
                width: 30px;

                .closeBtn {
                    background: red;
                    color: #fff;
                    font-size: 25px;
                    border-radius: 50px;
                }
            }
        }

        @include md {
            display: none;
        }
    }

    .mobileLeftMenu {
        display: none;
    }
}

// =================== End Mega Menu ======================
// =================== Start Footer Section ======================
.footerSection {
    background: var(--black);
    color: var(--white);

    @include md {
        margin-bottom: 0;
    }

    .footerLogoSection {
        p {
            text-align: justify;
            letter-spacing: 0.5px;
            margin-top: 10px;
            font-size: 15px !important;
            color: var(--text-gray);
        }

        ul {
            display: flex;
            justify-content: space-between;

            li {
                a {
                    font-size: 20px;
                }

                a:hover {
                    color: #fff !important;
                }
            }
        }
    }

    .footerNewsletter {
        margin-top: 3px;
        margin-bottom: 3px;

        h4 {
            font-size: 18px !important;
            text-transform: uppercase;
            font-weight: 600;
            position: relative;
            padding-bottom: 5px;
            color: var(--white);

            &:before {
                background: var(--orange);
                content: "";
                display: block;
                width: 80px;
                height: 2px;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }

        p {
            color: var(--text-gray);
        }

        input {
            border: 1px solid var(--text-gray);
            border-radius: 50px 0 0 50px;
            padding: 6px 0 9px 16px;
            width: 70%;
        }

        button {
            background: var(--orange);
            border: none;
            border-radius: 0 50px 50px 0;
            color: #fff;
            padding: 7px 2px 10px 0px;
            width: 30%;
        }
    }

    .blockFooter {
        .titleBlockFooter {
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 600;
            position: relative;
            padding-bottom: 5px;
            margin-bottom: 10px;
            margin-top: 5px;

            &:before {
                background: var(--orange);
                content: '';
                display: block;
                width: 80px;
                height: 2px;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
    }

    .contentBlockFooter {
        display: inline-block;
        vertical-align: top;

        ul {
            padding-left: unset;

            li {
                margin-bottom: 10px;

                .footerConnectIcons {
                    color: var(--orange);
                    margin-right: 10px;
                    font-size: 25px;
                }

                .footerConnectIcon {
                    font-size: 50px;
                    color: var(--orange);
                    margin-right: 10px;
                }

                span {
                    color: var(--text-gray);
                }

                a {
                    color: var(--text-gray);

                    &:hover {
                        color: var(--orange);
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .footerBottom {
        background-color: #23242e;
        padding: 10px 0;
        color: #fff;

        address {
            margin-top: 0px;
            margin-bottom: unset;
            line-height: inherit;
        }

        .footerPayment {
            text-align: right;
            display: none;

            @include md {
                text-align: right;
                display: block;
            }
        }
    }
}

// =================== End Footer Section ======================
//===================== Health Care =================
.healthCareSection {
    background: var(--white);
}

.healthCareList {
    ul {
        margin: 10px 0px 10px 10px;

        @include md {
            margin: unset;
            margin-bottom: 10px !important;
        }
    }
}

// =================== Start Best Seller ======================
.appSinglePricingCard {
    padding: 10px;
    -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    margin-bottom: 30px;
    border-radius: 10px;
    background-color: #ffffff;

    @include sm {
        margin: 10px;
    }

    .pricingTopContent {
        position: relative;

        .productImg {
            a {
                img {
                    width: 100%;
                    border-radius: 5px;
                }
            }
        }

        .pricingList {
            p {
                display: flex;
                justify-content: space-between;

                span {
                    color: var(--orange);
                    font-size: 20px;
                }

                del {
                    color: var(--black);
                    margin-left: 10px;
                }

                .productRatings {
                    line-height: normal;
                    display: inline-block;
                    vertical-align: middle;
                    background-color: var(--green);
                    padding: 4px 9px 0px 7px;
                    border-radius: 3px;
                    font-weight: 500;
                    color: #fff;
                    height: 25px;
                    text-align: center;

                    .ratingIcons {
                        margin-top: -1px;
                        margin-left: 3px;
                    }
                }
            }
        }

        .btnMainProduct {
            display: flex;
            justify-content: space-between;
        }
    }
}

// =================== End Best Seller ======================
// =================== Top Selling Product ======================
.topSellingSection {
    margin: 10px 0px 10px 0px;

    .topSellingCart {
        padding: 10px;
        border-radius: 10px;
        background: var(--white);

        @include md {
            display: flex;
            justify-content: space-between;
        }

        .topSellingImg {
            width: 100%;

            @include md {
                width: 50%;
            }

            img {
                width: 100%;
            }
        }

        .topSellingPriceList {
            width: 100%;
            margin-top: 10px;

            @include md {
                width: 50%;
                padding-left: 20px;
                margin-top: unset;
            }

            .pricingList {
                a {
                    text-decoration: none;

                    h4 {
                        height: 40px;
                    }
                }

                p {
                    display: flex;
                    justify-content: space-between;

                    .productPrice {
                        @include md {
                            display: unset;
                            flex-direction: unset;
                        }

                        span {
                            color: var(--orange);
                            font-size: 20px;
                        }

                        del {
                            color: var(--black);
                            margin-left: unset;

                            @include md {
                                margin-left: 10px;
                            }
                        }
                    }

                    .productRatings {
                        line-height: normal;
                        display: inline-block;
                        vertical-align: middle;
                        background-color: green;
                        padding: 4px 4px 3px 5px;
                        height: 25px;
                        border-radius: 3px;
                        font-weight: 500;
                        color: #fff;
                        text-align: center;

                        @include md {
                            padding: 4px 4px 3px 5px;
                        }

                        .ratingIcons {
                            margin-top: -1px;
                            margin-left: 3px;
                        }
                    }
                }

                .topProductDesc {
                    color: var(--black);
                    letter-spacing: .5px;
                    line-height: 25px;
                    font-size: 16px;
                    font-weight: 400;
                    margin-bottom: unset;
                    height: 100%;

                    @include md {
                        height: 165px;
                    }
                }
            }

            .btnMainProduct {
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
                gap: 5px !important;

                @include md {
                    justify-content: space-between;
                    flex-direction: unset;
                    width: 100%;
                    gap: unset;
                }

                button {
                    width: unset;
                    text-decoration: none;
                    padding: 8px 0px;
                    display: flex;
                    width: 100%;
                    justify-content: center;

                    @include md {
                        padding: 8px 15px;
                    }
                }
            }
        }
    }
}

// =================== End Selling Product ======================
// =================== Start Authentic Product ===================
.authenticProduct {
    .singleAppFeaturesBox {
        position: relative;
        z-index: 1;
        -webkit-transition: all ease 0.5s;
        transition: all ease 0.5s;

        &:before {
            content: '';
            position: absolute;
            background-color: #f5f3f6;
            width: 100%;
            height: 70%;
            z-index: -1;
            top: -20px;
            left: 0;
            border-radius: 10px;
        }

        .featuresContent {
            text-align: center;
            background-color: #ffffff;
            padding: 20px;
            -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
            box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
            margin: 20px 20px 30px 20px;
            position: relative;
            z-index: 2;
            border-radius: 10px;

            img {
                width: 50%;
            }

            p {
                font-size: 14px !important;
            }
        }
    }
}

// =================== End Authentic Product ======================
// =================== Testimonial ======================
.testimonialList {
    img {
        width: 100%;
    }
}

// =================== Testimonial ======================
// =================== Home Blog ======================
.homeBlog {
    .app-single-blog-card {
        -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        padding: 15px;
        margin-bottom: 20px;
        background: #fff;
        margin-right: 20px;

        .blog-img {
            overflow: hidden;
            -webkit-transition: all ease 0.5s;
            transition: all ease 0.5s;
            border-radius: 10px;

            img {
                -webkit-transition: all ease 0.5s;
                transition: all ease 0.5s;
                border-radius: 10px;
                width: 100%;

                &:hover {
                    -webkit-transform: scale(1.2);
                    transform: scale(1.2);
                }
            }
        }

        .blog-content {
            padding-top: 10px;

            .date-and-comment {
                margin-bottom: 20px;

                ul {
                    padding-left: 0;
                    margin-bottom: 0;
                    display: flex;
                    justify-content: space-between;

                    li {
                        display: inline-block;
                        position: relative;
                        margin-right: 15px;
                        padding-left: 20px;

                        .blogIcons {
                            position: absolute;
                            color: #07273c;
                            left: 0;
                            top: 2px;
                        }
                    }
                }
            }

            h3 {
                font-size: 15px !important;
                font-family: 'Inter', sans-serif;
                line-height: 1.4;
                height: 60px !important;

                @include md {
                    height: unset;
                }

                a {
                    color: #19191b;
                    -webkit-transition: all ease 0.5s;
                    transition: all ease 0.5s;
                    text-decoration: none;
                }
            }

            .app-default-btn.style3 {
                display: block;
                padding: 5px 0px;
                border: 1px solid var(--orange);
                text-align: center;
                color: var(--white);
                -webkit-transition: all ease 0.5s;
                transition: all ease 0.5s;
                border-radius: 10px;
                text-decoration: none;
                background: var(--black);
                font-size: 20px;
            }
        }
    }
}

// =================== Home Blog ======================
// =================== News letter ======================
.newsLetterContent {
    padding: unset;
    background: var(--white);
    border-radius: 10px;

    @include md {
        padding: 12px 78px;
    }

    .newsLetterUser {
        padding: unset;
        margin: auto;
        text-align: center;

        @include md {
            padding: 80px 50px;
        }

        input {
            width: 50%;
            border: 1px solid var(--text-gray);
            border-radius: 50px 0 0 50px;
            padding: 6px 0px 9px 16px;
        }

        button {
            border-radius: 0px 50px 50px 0px;
            padding: 7px 10px 10px 16px;
            background: var(--black);
            color: #fff;
            border: none;
        }

        h2 {
            font-weight: 600;
            color: var(--black);
        }

        p {
            color: var(--orange);
            font-size: 20px !important;
        }
    }

    .newsLetterImg {
        img {
            width: 100%;
        }
    }
}

// =================== News letter ======================
// =================== Login Page ======================
.userLoginCard {
    background: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    box-shadow: 0 1px 6px 0 hsla(0, 3%, 55%, 0.86);
    padding: 30px;
    width: 100%;
    margin-bottom: 20px;

    @include md {
        width: 50%;
        margin-left: 25%;
        margin-right: 25%;
    }

    .h1 {
        color: var(--black);
    }

    .label {
        font-size: 20px;
        color: var(--black);
    }

    .form-control {
        background: #f5f5f5;
        border: 1px solid #e8e8e8;
        border-radius: 6px;
        color: #555;
        height: 45px;
        line-height: 2;
        padding: 0 20px 2px;
        width: 100%;
    }

    .register {
        background: var(--black);
        color: var(--white);
        border: 1px solid var(--orange);
        width: 100%;
        text-align: center;
        padding: 5px 20px;
        border-radius: 10px;
        margin-top: 10px;
        font-size: 20px;

        &:hover {
            background: var(--white);
            color: var(--black);
            border: 1px solid var(--black);
        }
    }

    .resendMobileTop {
        text-decoration: none;
        position: relative;
        float: right;
        color: var(--orange);
        margin-top: 10px;
        border: none;
        background: none;
    }
}

// =================== Login Page ======================
.aboutSection {
    img {
        width: 100%;
    }
}

.contact-map {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 6px 0 hsla(0, 3%, 85%, .86);
    margin-top: 20px;
    padding: 20px;
}

.contact-form,
.getInTouch {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 6px 0 hsla(0, 3%, 85%, .86);
    padding: 20px;
}

.contactBtn {
    background: var(--black);
    color: var(--white);
    border: 1px solid var(--orange);
    width: 100%;
    text-align: center;
    padding: 5px 20px;
    border-radius: 10px;
    margin-top: 10px;
    font-size: 20px;

    &:hover {
        background: var(--white);
        color: var(--black);
        border: 1px solid var(--black);
    }
}

.getInTouch {
    margin-top: 20px;

    @include md {
        margin-top: unset;
    }

    ul {
        padding: 0px 0px 20px;

        li {
            padding: 10px 0px;

            .contactIcon {
                color: var(--orange);
                font-size: 25px;
            }

            span {
                font-size: 20px;
                margin-left: 20px;
            }
        }
    }
}

.contactUs {
    h1 {
        color: var(--black);
    }
}

// =================== Login Page ======================
// =================== Start Cart Page ====================
.cartItems {
    .cart-sidebar {
        position: fixed;
        top: 0px;
        right: 0px;
        width: 400px;
        height: 100vh;
        z-index: 5;
        background: var(--white);
        -webkit-box-shadow: -15px 0px 25px 0px rgba(0, 0, 0, 0.15);
        box-shadow: -15px 0px 25px 0px rgba(0, 0, 0, 0.15);
        transition: all linear 0.3s;
        -webkit-transition: all linear 0.3s;
        -moz-transition: all linear 0.3s;
        -ms-transition: all linear 0.3s;
        -o-transition: all linear 0.3s;

        &:active {
            right: 0px;
        }

        display: none;
    }

    .activeCartItem {
        display: block;
    }

    .cart-header {
        padding: 10px 0px;
        text-align: center;
        position: relative;
        border-bottom: 1px solid var(--orange);

        .cart-total {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;

            .basketIcon {
                font-size: 20px;
                margin-right: 8px;
                color: var(--orange);
            }

            span {
                font-weight: 500;
                color: var(--orange);
                text-transform: capitalize;
                font-size: 18px;
            }
        }

        .cart-close {
            position: absolute;
            top: 50%;
            left: -18px;
            border: none;
            background: none;

            .cartCloseIcon {
                width: 35px;
                height: 35px;
                font-size: 18px;
                line-height: 35px;
                border-radius: 50%;
                text-align: center;
                display: inline-block;
                color: var(--white);
                background: var(--orange);
                text-shadow: var(--black);
                transition: all linear 0.3s;
                -webkit-transition: all linear 0.3s;
                -moz-transition: all linear 0.3s;
                -ms-transition: all linear 0.3s;
                -o-transition: all linear 0.3s;
            }
        }
    }

    .cart-list {
        height: 100%;
        padding: 0px 15px;
        max-height: calc(100vh - 145px);
        overflow-y: scroll;

        .cart-item {
            padding: 15px 10px;
            display: flex;
            align-items: center !important;
            justify-content: center;
            align-items: flex-start !important;
            border-bottom: 1px solid var(--orange);

            .cart-media {
                position: relative;
                margin-right: 25px;

                a img {
                    width: 100px;
                    border-radius: 8px;
                }
            }

            .cart-info-group {
                width: 100%;

                .cart-info {
                    margin-bottom: 13px;

                    h6 {
                        font-weight: 600;
                        text-transform: capitalize;
                        font-size: 18px;
                        margin-top: 4px;
                        color: var(--black);
                    }

                    p {
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }

            .cart-delete {
                border: none;

                .cartItemTrash {
                    width: 25px;
                    height: 25px;
                    font-size: 18px;
                    line-height: 25px;
                    text-align: center;
                    border-radius: 5px;
                    display: inline-block;
                    color: var(--orange);
                    background: rgba(255, 255, 255, 0.9);
                    transition: all linear 0.3s;
                    -webkit-transition: all linear 0.3s;
                    -moz-transition: all linear 0.3s;
                    -ms-transition: all linear 0.3s;
                    -o-transition: all linear 0.3s;
                }
            }

            .cart-action-group {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;

                .product-action {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                }

                input {
                    width: 45px;
                    height: 30px;
                    font-size: 14px;
                    border-radius: 5px;
                    color: var(--black);
                    background: #80808000;
                    margin: 0px 10px 0px 10px;
                    text-align: center;
                }

                .action-minus,
                .action-plus {
                    border: none;
                }

                .cartQtyMinus {
                    width: 30px;
                    height: 30px;
                    font-size: 14px;
                    line-height: 30px;
                    border-radius: 6px;
                    text-align: center;
                    display: inline-block;
                    color: var(--white);
                    background: var(--orange);
                    text-shadow: var(--black);
                    transition: all linear 0.3s;
                    -webkit-transition: all linear 0.3s;
                    -moz-transition: all linear 0.3s;
                    -ms-transition: all linear 0.3s;
                    -o-transition: all linear 0.3s;
                }

                .cartQtyPlus {
                    width: 30px;
                    height: 30px;
                    font-size: 14px;
                    line-height: 30px;
                    border-radius: 6px;
                    text-align: center;
                    display: inline-block;
                    color: var(--white);
                    background: var(--orange);
                    text-shadow: var(--black);
                    transition: all linear 0.3s;
                    -webkit-transition: all linear 0.3s;
                    -moz-transition: all linear 0.3s;
                    -ms-transition: all linear 0.3s;
                    -o-transition: all linear 0.3s;
                }

                h6 {
                    font-weight: 500;
                    color: var(--black);
                    margin-right: -30px;
                    margin-top: 10px;
                }
            }
        }

        .cart-item:hover .cart-media button {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }

    .cart-footer {
        padding: 20px 15px 0px;
        text-align: center;
        -webkit-box-shadow: 0px -3px 7px 0px rgba(0, 0, 0, 0.08);
        box-shadow: 0px -3px 7px 0px rgba(0, 0, 0, 0.08);

        .cart-checkout-btn {
            padding: 10px 0px;
            border-radius: 8px;
            background: var(--black);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            transition: all linear 0.3s;
            -webkit-transition: all linear 0.3s;
            -moz-transition: all linear 0.3s;
            -ms-transition: all linear 0.3s;
            -o-transition: all linear 0.3s;
            border: 1px solid var(--orange);

            span {
                color: #fff;
            }
        }

        a {
            text-decoration: none;
        }
    }
}

// =================== End Cart Page ======================
// =================== Product Page ======================
.shop-widget {
    padding: 10px 10px;
    border-radius: 8px;
    background: var(--white);
    border: 1px solid var(--white);

    .shop-widget-title {
        padding-bottom: 12px;
        text-transform: uppercase;
        border-bottom: 1px solid var(--text-gray);
        color: var(--black);
    }

    .shop-widget-list {
        li {
            margin-bottom: 5px;
            color: var(--gray);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            transition: all linear .3s;
            -webkit-transition: all linear .3s;
            -moz-transition: all linear .3s;
            -ms-transition: all linear .3s;
            -o-transition: all linear .3s;

            .shop-widget-content {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: start;
                -ms-flex-pack: start;
                justify-content: flex-start;

                input {
                    width: 15px;
                    height: 15px;
                    margin-right: 12px;
                    cursor: pointer;
                }

                label {
                    cursor: pointer;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    text-transform: capitalize;
                    font-size: 14px !important;

                    &:hover {
                        color: var(--orange);
                        font-weight: 600;
                    }
                }
            }

        }
    }
}

.productTopBar {
    background: #fff;
    padding: 10px 0px 0px 6px;
    border-radius: 5px;
    display: none;

    @include md {
        display: flex;
        justify-content: space-between;
    }

    .productViewIcon {
        .productLeft {
            font-size: 20px;
            margin-right: 10px;
            rotate: 90deg;
            cursor: pointer;
        }

        .productDown {
            font-size: 20px;
            cursor: pointer;
        }
    }
}

.productDownView {
    .appSinglePricingCard {
        padding: 10px !important;
        width: 100%;
        -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
        box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
        margin-bottom: 30px;
        border-radius: 10px;
        background-color: #ffffff;

        @include sm {
            width: 300px;
        }

        .pricingTopContent {
            position: relative;

            .productImg {
                margin-bottom: 30px;

                a {
                    img {
                        width: 100%;
                    }
                }
            }

            p {
                display: none;
            }

            .pricingList {
                margin-bottom: 30px;

                a {
                    text-decoration: none;
                }

                p {
                    display: flex;
                    justify-content: space-between;

                    span {
                        color: var(--orange);
                        font-size: 20px;
                    }

                    del {
                        color: var(--black);
                        margin-left: 10px;
                    }

                    .productRatings {
                        line-height: normal;
                        display: inline-block;
                        vertical-align: middle;
                        background-color: green;
                        padding: 3px 8px 0px 6px;
                        border-radius: 3px;
                        font-weight: 500;
                        color: #fff;
                        text-align: center;

                        .ratingIcons {
                            margin-top: -1px;
                            margin-left: 3px;
                        }
                    }
                }
            }

            .btnMainProduct {
                display: flex;
                justify-content: space-between;

                a {
                    text-decoration: none;
                }
            }
        }
    }
}

.productLeftView {
    .appSinglePricingCard {
        width: 100% !important;
        height: 300px;

        .pricingTopContent {
            display: flex;
            justify-content: space-between;

            .productImg {
                width: 26%;
                margin-bottom: unset;
            }

            .productContentList {
                width: 74%;
                padding: 0px 0px 0px 25px;

                .pricingList {
                    a {
                        text-decoration: none;
                    }
                }
            }

            .productRatings {
                float: right;
                position: absolute;
                right: 0;
                top: 0;
                padding: 2px 8px 0px 6px !important;
            }

            .productDsc {
                display: block;
            }

            .btnMainProduct {
                a {
                    text-decoration: none;
                }
            }
        }
    }
}

// =================== End Product Page ======================
// =================== Health Care ======================
.healthCareList {
    img {
        width: 100%;
    }
}

.dealsSection {
    a {
        text-decoration: none;
    }
}

// =================== End Health Care ======================
// =================== Product Details ======================
.productDetails {
    padding: unset;
    border-radius: 5px;

    h1 {
        font-weight: 500;
        letter-spacing: .5px;
        font-size: 1.2rem !important;
        color: #000000cf !important;
        margin-top: 10px !important;

        @include md {
            margin-top: -7px !important;
        }
    }

    @include md {
        padding: 0px 10px 10px;
    }

    .inner-shop-details-meta {
        ul {
            gap: 5px;
            padding-left: unset !important;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            li {
                list-style: none;
                font-size: 16px;
                color: #777777;
                padding-right: 15px;
                margin-right: 15px;
                position: relative;
                margin-top: 11px;

                a {
                    color: var(--black);
                    font-size: 15px;

                    &::before {
                        content: "|";
                        position: absolute;
                        right: -2px;
                        top: 50%;
                        transform: translateY(-50%);
                        color: #dadada;
                    }
                }
            }
        }
    }
}

.pro-price {
    margin-top: 10px;
    display: flex;
    align-items: center;

    span.new-price {
        font-size: 26px;
        font-weight: 600;
        color: var(--orange);
    }

    span.old-price {
        color: #666666;
        margin-left: 10px;
    }

    .Pro-lable {
        display: flex;
    }

    .Pro-lable span.p-discount {
        background-color: var(--green);
        font-size: 15px;
        color: var(--white);
        height: 25px;
        margin-left: 10px;
        padding: 2px 10px 2px 10px;
        margin-top: 13px;
        border-radius: 5px;
    }

    .Pro-lable span.p-discount-sticky {
        background-color: var(--orange2);
        font-size: 15px;
        color: var(--white);
        height: 25px;
        margin-left: 10px;
        padding: 2px 10px 2px 10px;
        margin-top: 0px;
    }

    .Pro-lable p {
        display: none;

        @include md {
            display: block;
            margin-left: 10px;
            color: #666666;
            font-size: 13px !important;
            margin-top: 12px !important;
        }
    }
}

.productdetailsdesc {
    letter-spacing: 0.6px !important;
    font-weight: 400 !important;
    line-height: 1.74 !important;
    font-size: 15px !important;
    font-style: normal;
    visibility: visible;
    color: #696969;
}

.inner-shop-details-review span {
    background-color: #388e3c;
    padding: 0px 5px;
    color: var(--white);
    border-radius: 5px;
    margin-left: 2px;
}

.product-details {
    ul {
        li {
            letter-spacing: .6px;
            font-weight: 400;
            line-height: 1.74;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            visibility: visible;
            color: #696969;
            display: flex;
        }

        .productDescCheck {
            color: #209320;
            margin-right: 5px;
            margin-top: -5px;
            font-weight: bold;
            font-size: 14px;
        }
    }
}

.pro-details {
    font-size: 18px;
    font-weight: 600;
    color: #008000;
}

.paymentType {
    .securePayment {
        padding: 0px !important;
        background: #fff;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, .3);
        margin-top: 10px;

        @include md {
            margin-top: unset;
            width: 100%;
            text-align: center;
            padding: 5px 5px 5px;
        }

        h6 {
            text-align: center;
            font-size: .8rem !important;
            font-weight: 400 !important;

            @include md {
                font-size: 1rem !important;
            }
        }

        p {
            text-align: center;
            letter-spacing: 0.3px;
            font-size: 17px !important;
            line-height: 1.5;
        }
    }
}

.productBtn {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;

    button {
        font-size: 15px;
        padding: 5px 40px;
        line-height: 25px;
        letter-spacing: 1px;

        @include md {
            padding: 5px 100px 5px 100px;
            font-size: 15px;
        }
    }

    .addToCart {
        background: var(--white);
        border: 1px solid var(--black);
        color: var(--black);
        border-radius: 5px;

        &:hover {
            background: var(--black);
            color: var(--white);
            border: 1px solid var(--black);
        }
    }

    .buyItNow {
        background: var(--orange);
        border: 1px solid var(--orange);
        color: var(--white);
        border-radius: 5px;

        &:hover {
            background: var(--white);
            color: var(--black);
            border: 1px solid var(--black);
        }
    }
}

.sectionMenu {
    .contentWrapper {
        padding: unset;

        @include md {
            padding: 0 20px;
        }
    }
}

.sectionMenu {
    position: sticky;
    top: 145px;
    z-index: 1;

    @include md {
        top: 95px;
    }

    .productDetailsMenu {
        display: -webkit-inline-box;
        overflow-x: scroll;
        position: sticky;
        overflow-y: hidden;
        justify-content: space-between;
        padding: 3px 0;
        background: #fff;
        justify-content: space-between;
        -webkit-box-shadow: 0px 10px 15px -7px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 10px 15px -7px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 10px 15px -7px rgba(0, 0, 0, 0.75);
        width: 100%;
        border-top: 1px solid #80808021;

        &::-webkit-scrollbar {
            display: none;
        }

        @include md {
            width: 100%;
            overflow-x: scroll;
            position: sticky;
            overflow-y: hidden;
            justify-content: space-between;
            box-sizing: content-box;
            overflow: hidden;

        }

        li {
            margin-right: 5px;
            margin-left: 5px;

            a {
                color: var(--black);
                font-size: 13px;
                letter-spacing: .6px;

                &:active {
                    font-weight: 700;
                    transform: scale(1.2);
                }

                span {
                    margin-left: 10px;
                    color: #c1bebe;
                }
            }
        }
    }
}

.sectionLPMenus {
    position: sticky;
    top: 0px;
    z-index: 1;

    @include md {
        top: 0px;
    }

    .productDetailsMenu {
        display: -webkit-inline-box;
        overflow-x: scroll;
        position: sticky;
        overflow-y: hidden;
        justify-content: space-between;
        padding: 3px 0;
        background: #fff;
        justify-content: space-between;
        -webkit-box-shadow: 0px 10px 15px -7px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 10px 15px -7px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 10px 15px -7px rgba(0, 0, 0, 0.75);
        width: 100%;
        border-top: 1px solid #80808021;

        &::-webkit-scrollbar {
            display: none;
        }

        @include md {
            width: 100%;
            overflow-x: scroll;
            position: sticky;
            overflow-y: hidden;
            justify-content: space-between;
            box-sizing: content-box;
            overflow: hidden;

        }

        li {
            margin-right: 5px;
            margin-left: 5px;

            a {
                color: var(--black);
                font-size: 13px;
                letter-spacing: .6px;

                &:active {
                    font-weight: 700;
                    transform: scale(1.2);
                }

                span {
                    margin-left: 10px;
                    color: #c1bebe;
                }
            }
        }
    }
}

.sectionLPMenu {
    position: sticky;
    top: 105px;
    z-index: 1;

    @include md {
        top: 95px;
    }

    .productDetailsMenu {
        display: -webkit-inline-box;
        overflow-x: scroll;
        position: sticky;
        overflow-y: hidden;
        justify-content: space-between;
        padding: 3px 0;
        background: #fff;
        justify-content: space-between;
        -webkit-box-shadow: 0px 10px 15px -7px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 10px 15px -7px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 10px 15px -7px rgba(0, 0, 0, 0.75);
        width: 100%;
        border-top: 1px solid #80808021;

        &::-webkit-scrollbar {
            display: none;
        }

        @include md {
            width: 100%;
            overflow-x: scroll;
            position: sticky;
            overflow-y: hidden;
            justify-content: space-between;
            box-sizing: content-box;
            overflow: hidden;

        }

        li {
            margin-right: 5px;
            margin-left: 5px;

            a {
                color: var(--black);
                font-size: 13px;
                letter-spacing: .6px;

                &:active {
                    font-weight: 700;
                    transform: scale(1.2);
                }

                span {
                    margin-left: 10px;
                    color: #c1bebe;
                }
            }
        }
    }
}

.QRCodeProduct {
    margin-top: 20px;
    text-align: center;
    display: none;

    @include md {
        display: block;
        position: sticky;
        z-index: 10;
        top: 430px;
        padding: 10px 10px 10px 10px;
        background: #fff;
        box-shadow: 0 2px 6px 0 rgba(218, 225, 259, 0.85), 0 5px 8px 0 rgba(214, 188, 238, 0.95);
    }

    h5 {
        margin-top: 10px;
    }
}

.stickyBuySection {
    display: none;

    @include md {
        display: block;
        position: sticky;
        top: 115px;
        padding: 10px 10px 10px 10px;
        background: #fff;
        box-shadow: 0 2px 6px 0 rgba(218, 225, 259, 0.85), 0 5px 8px 0 rgba(214, 188, 238, 0.95);
    }

    h4 {
        font-size: 1.2rem;
        line-height: 2rem;
    }

    p {
        letter-spacing: 0.6px;
        font-size: 14px !important;
        line-height: 1.5;
    }

    button {
        width: 100%;
        background: var(--black);
        border: 1px solid var(--orange);
        color: var(--white);
        border-radius: 5px;
        padding: 3px;
        font-size: 18px;

        &:hover {
            background: var(--white);
            color: var(--black);
            border: 1px solid var(--black);
        }
    }
}

.productDetailSection {
    .processBox {
        box-shadow: 0 2px 6px 0 rgba(218, 225, 259, 0.85), 0 5px 8px 0 rgba(214, 188, 238, 0.95);
        background: #fff;
        padding: 10px;

        h4 {
            color: var(--black);
            font-family: 'Inter', sans-serif;
            font-weight: 600;
            font-size: 20px;

            .icons {
                margin-right: 5px;
                margin-top: -10px;
            }

            img {
                width: 100%;
            }
        }

        p {
            font-family: 'Inter', sans-serif;
            margin-bottom: unset;

            .orangeicons {
                color: var(--orange);
                margin-right: 5px;
                font-size: 20px;
                font-weight: 600;
            }

            .greenicons {
                color: green;
                margin-right: 5px;
                font-size: 20px;
                font-weight: 600;
            }
        }
    }

    .ProductDetails {
        .product-details-points {
            display: flex;
            letter-spacing: .3px;
            font-size: 17px !important;
            line-height: 1.8;
            color: #1a2227;
            margin-bottom: 10px;

            td {
                span {
                    font-weight: bold;
                    font-size: 20px;
                    margin-right: 5px;
                }
            }
        }
    }
}

.sass-solution-single-card {
    padding: 10px;
    margin-bottom: 10px;
    -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 6px 0 rgba(218, 225, 255, 0.85), 0 5px 8px 0 rgba(214, 188, 238, 0.95);
    background-color: #ffffff;
    border-radius: 10px;
    -webkit-transition: all ease 0.5s;
    transition: all ease 0.5s;
    position: relative;
}

.sass-solution-single-card .solution-content {
    position: relative;
    text-align: center;
}

.sass-solution-single-card .solution-content .icon {
    height: 40px;
    width: 40px;
    line-height: 43px;
    border-radius: 100%;
    text-align: center;
    color: #0797ff;
    font-size: 16px;
    position: absolute;
    left: 0;
    -webkit-transition: all ease 0.5s;
    transition: all ease 0.5s;
}

.sass-solution-single-card .solution-content h3 {
    font-size: 22px;
    margin-bottom: 15px;
    -webkit-transition: all ease 0.5s;
    transition: all ease 0.5s;
    font-weight: 600;
}

.sass-solution-single-card .solution-content p {
    font-size: 15px;
    -webkit-transition: all ease 0.5s;
    transition: all ease 0.5s;
}

.sass-solution-single-card .solution-content a {
    color: #0797ff;
    -webkit-transition: all ease 0.5s;
    transition: all ease 0.5s;
}

.sass-solution-single-card::before {
    position: absolute;
    content: '';
    height: 100%;
    height: 100%;
    border-radius: 10px;
    width: 0;
    top: 0;
    right: 0;
    background-color: var(--black);
    -webkit-transition: all ease 0.5s;
    transition: all ease 0.5s;
}

.sass-solution-single-card:hover::before {
    width: 100%;
    border-radius: 0;
    left: 0;
    right: auto;
    border-radius: 10px;
}

.sass-solution-single-card:hover .solution-content .icon {
    color: #ffffff;
    border-color: #ffffff;
}

.sass-solution-single-card:hover .solution-content h3 {
    color: #ffffff;
}

.sass-solution-single-card:hover .solution-content p {
    color: #ffffff;
}

.sass-solution-single-card:hover .solution-content a {
    color: #ffffff;
}

.main-timeline9 {
    position: relative;
    z-index: 0;
}

.main-timeline9:after,
.main-timeline9:before {
    content: "";
    display: block;
    width: 100%;
    clear: both;
}

.main-timeline9:before {
    content: "";
    width: 6px;
    height: 100%;
    background: #07273c;
    position: absolute;
    top: 0;
    left: 50%;
}

.main-timeline9 .timeline {
    width: 50%;
    float: left;
    position: relative;
    z-index: 1;
}

.main-timeline9 .timeline:after,
.main-timeline9 .timeline:before {
    content: "";
    display: block;
    width: 100%;
    clear: both;
}

.main-timeline9 .timeline:first-child:before,
.main-timeline9 .timeline:last-child:before {
    content: "";
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #fff;
    border: 4px solid #07273c;
    position: absolute;
    top: 0;
    right: -14px;
    z-index: 1;
}

.main-timeline9 .timeline:last-child:before {
    top: auto;
    bottom: 0;
}

.main-timeline9 .timeline:last-child:nth-child(even):before {
    right: auto;
    left: -12px;
    bottom: -2px;
}

.main-timeline9 .timeline-content {
    text-align: center;
    margin-top: 8px;
    position: relative;
    transition: all .3s ease 0s;
}

.main-timeline9 .timeline-content:before {
    content: "";
    width: 100%;
    height: 5px;
    background: #07273c;
    position: absolute;
    top: 88px;
    left: 0;
    z-index: -1;
}

.main-timeline9 .circle {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background: #fff;
    border: 8px solid #07273c;
    float: left;
    margin-right: 25px;

    position: relative img {
        width: 100%;
    }
}

.main-timeline9 .circle span:after,
.main-timeline9 .circle span:before,
.main-timeline9 .circle:before {
    content: "";
    margin: auto;
    position: absolute;
    right: -33px;
    bottom: 0;
    z-index: -1;
}

.main-timeline9 .circle:before {
    width: 26px;
    height: 30px;
    background: #07273c;
    top: 0;
    box-shadow: inset 7px 0 9px -7px #444;
}

.main-timeline9 .circle span {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    line-height: 160px;
    color: #454344;
}

.main-timeline9 .circle span:after,
.main-timeline9 .circle span:before {
    width: 28px;
    height: 50px;
    background: #fff;
    border-radius: 0 0 0 21px;
    top: -54px;
}

.main-timeline9 .circle span:after {
    border-radius: 21px 0 0;
    top: 0;
    bottom: -56px;
}

.main-timeline9 .content {
    display: table;
    padding-right: 40px;
    position: relative;
}

.main-timeline9 .year {
    display: block;
    padding: 4px;
    margin: 45px 0 17px;
    background: #07273c;
    border-radius: 7px;
    font-size: 20px;
    color: #fff;
}

.main-timeline9 .title {
    font-size: 18px;
    font-weight: 600;
    color: #07273c;
    margin-top: 0;
}

.main-timeline9 .icon span:after,
.main-timeline9 .icon span:before,
.main-timeline9 .icon:before {
    content: "";
    height: 25px;
    margin: auto;
    position: absolute;
    bottom: 0;
    z-index: -1;
    left: -15px;
}

.main-timeline9 .description {
    text-align: justify;
    color: #1a2227;
    font-size: 15px !important;
}

.main-timeline9 .icon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #fff;
    border: 4px solid #07273c;
    position: absolute;
    top: 78px;
    right: -14px;
}

.main-timeline9 .icon:before {
    width: 15px;
    background: #07273c;
    top: -1px;
}

.main-timeline9 .icon span:after,
.main-timeline9 .icon span:before {
    width: 21px;
    background: #fff;
    border-radius: 0 0 21px;
    top: -30px;
}

.main-timeline9 .icon span:after {
    border-radius: 0 21px 0 0;
    top: 0;
    left: -15px;
    bottom: -30px;
}

.main-timeline9 .timeline:nth-child(2n) .circle,
.main-timeline9 .timeline:nth-child(2n) .timeline-content {
    float: right;
}

.main-timeline9 .timeline:nth-child(2n) .circle {
    margin: 0 0 0 25px;
}

.main-timeline9 .timeline:nth-child(2n) .circle:before {
    right: auto;
    left: -33px;
    box-shadow: -7px 0 9px -7px #444 inset;
}

.main-timeline9 .timeline:nth-child(2n) .circle span:after,
.main-timeline9 .timeline:nth-child(2n) .circle span:before {
    right: auto;
    left: -33px;
    border-radius: 0 0 21px;
}

.main-timeline9 .timeline:nth-child(2n) .circle span:after {
    border-radius: 0 21px 0 0;
}

.main-timeline9 .timeline:nth-child(2n) .content {
    padding: 0 0 0 40px;
    margin-left: 2px;
}

.main-timeline9 .timeline:nth-child(2n) .icon {
    right: auto;
    left: -14px;
}

.main-timeline9 .timeline:nth-child(2n) .icon span:after,
.main-timeline9 .timeline:nth-child(2n) .icon span:before,
.main-timeline9 .timeline:nth-child(2n) .icon:before {
    left: auto;
    right: -15px;
}

.main-timeline9 .timeline:nth-child(2n) .icon span:before {
    border-radius: 0 0 0 21px;
}

.main-timeline9 .timeline:nth-child(2n) .icon span:after {
    border-radius: 21px 0 0;
}

.main-timeline9 .timeline:nth-child(2) {
    margin-top: 180px;
}

.main-timeline9 .timeline:nth-child(odd) {
    margin: -175px 0 0;
}

.main-timeline9 .timeline:nth-child(even) {
    margin-bottom: 80px;
}

.main-timeline9 .timeline:first-child,
.main-timeline9 .timeline:last-child:nth-child(even) {
    margin: 0;
}

@media only screen and (max-width:990px) {
    .main-timeline9:before {
        left: 100%;
    }

    .main-timeline9 .timeline {
        width: 100%;
        float: none;
        margin-bottom: 20px !important;
    }

    .main-timeline9 .timeline:first-child:before,
    .main-timeline9 .timeline:last-child:before {
        left: auto !important;
        right: -13px !important;
    }

    .main-timeline9 .timeline:nth-child(2n) .circle {
        float: left;
        margin: 0 25px 0 0;
    }

    .main-timeline9 .timeline:nth-child(2n) .circle:before {
        right: -33px;
        left: auto;
        box-shadow: 7px 0 9px -7px #444 inset;
    }

    .main-timeline9 .timeline:nth-child(2n) .circle span:after,
    .main-timeline9 .timeline:nth-child(2n) .circle span:before {
        right: -33px;
        left: auto;
        border-radius: 0 0 0 21px;
    }

    .main-timeline9 .timeline:nth-child(2n) .circle span:after {
        border-radius: 21px 0 0;
    }

    .main-timeline9 .timeline:nth-child(2n) .content {
        padding: 0 40px 0 0;
        margin-left: 0;
    }

    .main-timeline9 .timeline:nth-child(2n) .icon {
        right: -14px;
        left: auto;
    }

    .main-timeline9 .timeline:nth-child(2n) .icon span:after,
    .main-timeline9 .timeline:nth-child(2n) .icon span:before,
    .main-timeline9 .timeline:nth-child(2n) .icon:before {
        left: -15px;
        right: auto;
    }

    .main-timeline9 .timeline:nth-child(2n) .icon span:before {
        border-radius: 0 0 21px;
    }

    .main-timeline9 .timeline:nth-child(2n) .icon span:after {
        border-radius: 0 21px 0 0;
    }

    .main-timeline9 .timeline:nth-child(2),
    .main-timeline9 .timeline:nth-child(even),
    .main-timeline9 .timeline:nth-child(odd) {
        margin: 0;
        z-index: 1;
    }
}

@media only screen and (max-width:480px) {
    .main-timeline9:before {
        left: 0;
    }

    .main-timeline9 .timeline:first-child:before,
    .main-timeline9 .timeline:last-child:before {
        left: -12px !important;
        right: auto !important;
    }

    .main-timeline9 .circle,
    .main-timeline9 .timeline:nth-child(2n) .circle {
        float: none;
        margin: 0 auto;

        img {
            width: 100%;
        }
    }

    .main-timeline9 .timeline-content:before {
        width: 99.5%;
        top: 68px;
        left: .5%;
    }

    .main-timeline9 .circle span {
        line-height: 104px;
    }

    .main-timeline9 .circle span:after,
    .main-timeline9 .circle span:before,
    .main-timeline9 .circle:before,
    .main-timeline9 .icon {
        display: none;
    }

    .main-timeline9 .content,
    .main-timeline9 .timeline:nth-child(2n) .content {
        padding: 0 10px;
    }

    .main-timeline9 .year {
        margin-bottom: 15px;
    }

    .main-timeline9 .description {
        text-align: left;
    }

    .form-holder .form-content {
        margin-top: 80px;
    }
}

.benefitDetails {
    ul {
        li {
            letter-spacing: .5px;
            line-height: 1.8;
            color: #1a2227;
            margin-bottom: 10px;
            font-family: 'Inter', sans-serif;
            display: flex;
            box-shadow: 0 2px 6px 0 rgba(218, 225, 259, 0.85), 0 5px 8px 0 rgba(214, 188, 238, 0.95);
            background: #fff;
            padding: 10px;
            font-size: 15px !important;

            .greenicons {
                color: green;
                margin-right: 5px;
                font-size: 20px;
                font-weight: 600;
            }
        }
    }
}

.comparisonDetails {
    .btn-product {
        padding: 5px 20px;
        border: none;
        background: #489879;
        color: #fff;
        font-size: 15px;
        border-radius: 5px;
        width: 100%;
        font-weight: 600;
    }

    .btn-other {
        padding: 5px 20px;
        border: none;
        background: #ed5554;
        color: #fff;
        font-size: 15px;
        border-radius: 5px;
        width: 100%;
        font-weight: 600;
    }

    .greenicons {
        color: green;
        margin-right: 5px;
        font-size: 25px;
        font-weight: 600;
        margin-top: 3px;
    }

    .redicons {
        color: red;
        margin-right: 5px;
        font-size: 25px;
        font-weight: 600;
        margin-top: 3px;
    }

    .comparisonRightimg {
        img {
            width: 100% !important;
        }

    }

    .comparisonWrongimg {
        img {
            width: 93% !important;

            @include md {
                width: 100% !important;
            }
        }
    }
}

.reviewsDetails {
    .reviews-details .customer-reviews {
        border-top: 1px solid #eee;
        margin-top: 10px;
        padding-top: 20px;
    }

    .product-reviews-rating .orng_star_count {
        background: #388e3c;
        padding: 2px 9px 0px;
        border-radius: 5px;
        color: #fff;
        font-size: 14px;
        float: left;
        margin-right: 10px;
    }

    .user-name {
        display: unset;

        @include md {
            display: flex;
            justify-content: space-between;
        }
    }

    .customer-reviews .product-reviews-rating .user-name h6 {
        color: var(--black);
        margin: 0;
        padding: 0;
        font-weight: 600;
        line-height: normal;
        font-size: 14px;
        margin-bottom: 10px;
    }

    .user-location {
        color: var(--orange) !important;
    }

    p {
        line-height: 1.8;
        color: #1a2227;
        margin-top: 5px;
        margin-bottom: 0px;
        font-family: 'Inter', sans-serif;
    }

    .review-date-like {
        display: flex;
        justify-content: space-between;
    }

    .review-date-like .date {
        font-size: 15px;
        font-weight: 600;
        color: var(--orange);
    }

    .plikes {
        color: green;
        margin-top: -5px;
    }
}

.reviewsDetails .customer-reviews {
    letter-spacing: 0.3px;
    font-size: 14px !important;
    line-height: 1.8;
    color: #1a2227;
    margin-bottom: 10px;
    font-family: 'Inter', sans-serif;
    box-shadow: 0 2px 6px 0 rgba(218, 225, 255, 0.85), 0 5px 8px 0 rgba(214, 188, 238, 0.95);
    background: #fff;
    padding: 10px;
}

.faqDetails {
    .pfaqs {
        margin-top: 10px;
    }
}

.relatedSellerSection {
    .appContainer {
        max-width: 900px;
        margin: 22px auto -10px auto;
        text-align: center;
        position: relative;
    }
}

// =================== End Product Details ======================
// =================== Checkout ======================
.account-card {
    background: #fff;
    border-radius: 8px;
    margin-bottom: 30px;
    padding: 0 30px 30px;

    .account-title {
        align-items: center;
        border-bottom: 1px solid #e8e8e8;
        display: flex;
        justify-content: center;
        justify-content: space-between;
        margin-bottom: 15px;
        padding: 10px 0;
        position: relative;
    }

    .account-title:before {
        background: var(--orange);
        bottom: -2px;
        content: "";
        height: 1px;
        left: 0;
        position: absolute;
        width: 100px;
    }

    .account-content {
        label {
            color: var(--black);
            font-size: 18px;
        }

        .btncheckcont {
            width: 100%;
            background-color: var(--black);
            color: var(--white);
            text-align: center;
            padding: 10px 20px;
            border-radius: 10px;
            border: 1px solid var(--orange);
            margin-top: 10px;
        }
    }
}

.secureLogin {
    float: right;

    h4 {
        color: var(--orange);
    }

    ul {
        li {
            font-size: 18px;

            span {
                margin-left: 5px;
            }
        }
    }
}

.badge-pill {
    background: var(--orange);
    border-radius: 50px;
    color: #fff;
    margin-left: 10px;
    padding: 5px 11px;
}

.apply-coupon {
    display: flex;
    margin-bottom: 10px;
}

.apply-coupon input {
    background-position: 20px;
    border: 1px solid #ececec;
    border-radius: 10px 0 0 10px;
    box-shadow: none;
    font-size: 16px;
    height: 51px;
    width: 100%;
}

.apply-coupon button {
    background-color: var(--black);
    border-radius: 0 10px 10px 0;
    height: 51px;
    min-width: 150px;
    padding-top: 4px;
    color: var(--white);

    &:hover {
        color: var(--white);
    }
}

.checkoutCartDetails {
    position: sticky;
    z-index: 1;
    top: 100px;
}

.checkoutCart {
    z-index: 1;
}

.paymentType {
    display: flex;
    gap: 6px;
    margin-top: 10px;

    @include md {
        gap: 20px;
    }

    .paymentmethod {
        input {
            margin-right: 5px;
        }
    }
}

.form-control:focus {
    border: 1px solid #ced4da;
    outline: 0;
    box-shadow: none;
}

// =================== End Checkout ==================
// =================== Start 404 ================
.error404Img {
    margin: auto;
    padding: 10px;
    text-align: center;
    width: 100%;

    @include md {
        width: 650px;
    }

    .img {
        display: flex;
        justify-content: center;

        img {
            width: 50%;

            @include md {
                width: 25%;
            }
        }
    }

    .contenterror {
        h4 {
            color: var(--black);
            font-weight: 600;
            text-transform: uppercase;
        }

        p {
            margin-bottom: unset;

            a {
                text-decoration: none;
            }
        }
    }
}

.errorbreadcum {
    margin: unset !important;
}

// =================== End 404 ==================
// =================== User Dashboard =================
.right-sideabr {
    margin-bottom: 30px;
    box-shadow: 0px 0px 5px #ddd;

    h4 {
        font-size: 18px;
        color: var(--white);
        padding: 9px 20px;
        font-weight: 500;
        background-color: var(--orange);
        margin-bottom: 0;
    }

    .list-item li {
        padding: 7px 20px;
        border-bottom: 1px solid #efefef;
        margin: 0;
        font-weight: 500;
        background: white;
    }

    .list-item li a {
        color: var(--black);
    }

    .list-item li a:hover {
        color: var(--orange);
    }

    .list-item li a.active {
        color: var(--orange);
    }
}

.fomes {
    background-color: var(--orange);
    color: var(--white);
    margin: 0px;
    padding: 11px 20px;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: normal;
}

.contact-form-div {
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px #ddd;
}

.myOrderSection {
    .contact-form-div {
        scroll-behavior: smooth;
        overflow-y: auto;
    }
}

.contact-form-div input[type=text],
.contact-form-div input[type=email],
.contact-form-div input[type=password],
.contact-form-div input[type=url],
.contact-form-div input[type=search],
.contact-form-div input[type=tel],
.contact-form-div textarea {
    border: 1px solid #ddd;
    padding: 10px 10px;
    margin-bottom: 20px;
    width: 100%;
    box-shadow: none;
    outline: none;
    font-size: 18px;
    border-radius: 5px;
    background: #fbfbfb;
}

.userBtn {
    background-color: var(--black);
    color: var(--white);
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid var(--orange);
    text-align: center;
}

.imgProfilePic {
    width: 100%;
    height: 220px;
}

.profilePic {
    display: none;
}

.labelProfilePic {
    background-color: var(--black);
    color: var(--white);
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid var(--orange);
    margin-top: 10px;
    text-align: center;
    cursor: pointer;
    width: 100%;
}

// =================== End Dashboard ==================
// =================== Start Mobile Sticky ================
.mobileSticky {
    @include md {
        display: none;
    }

    .mobileMenu {
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        z-index: 3;
        background: var(--white);
        -webkit-box-shadow: 0px -5px 15px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px -5px 15px 0px rgba(0, 0, 0, 0.1);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex !important;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        display: block;

        a,
        button {
            text-decoration: none;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            width: 80px;
            padding: 15px 0px;
            font-weight: 600;
            background: none;
            border: none;
            color: var(--orange);
            position: relative;
        }

        button {
            .basketIcon {
                color: var(--orange);
                font-size: 25px;
            }

            sup {
                position: absolute;
                top: -5px;
                left: 75%;
                width: 24px;
                height: 24px;
                font-size: 12px;
                line-height: 20px;
                border-radius: 50%;
                text-align: center;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                color: var(--white);
                background: var(--orange);
                border: 2px solid green;
            }
        }

        a {
            svg {
                font-size: 25px;
            }
        }
    }

    .mcart-sidebar.actives {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: top;
        transform-origin: top;
        position: fixed;
    }

    .mcart-sidebar {
        position: fixed;
        top: 0px;
        height: 100vh;
        z-index: 5;
        background: var(--white);
        -webkit-box-shadow: -15px 0px 25px 0px rgba(0, 0, 0, 0.15);
        box-shadow: -15px 0px 25px 0px rgba(0, 0, 0, 0.15);
        width: 100%;
        height: 100vh;
        -webkit-transform: scale(0);
        transform: scale(0);
        margin: 120px 0px 0px;
        border-radius: 15px 15px 0px 0px;
        transition: all linear 0.3s;
        -webkit-transition: all linear 0.3s;
        -moz-transition: all linear 0.3s;
        -ms-transition: all linear 0.3s;
        -o-transition: all linear 0.3s;

        .cart-header {
            padding: 18px 25px;
            text-align: center;
            position: relative;
            border-bottom: 1px solid var(--orange);

            .cartbasketIcon,
            span {
                color: var(--orange);
                font-size: 18px;
            }
        }

        .cart-total {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            margin-top: 10px;
        }

        .cart-close {
            left: 50%;
            top: -11px;
            bottom: inherit;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            position: absolute;
            background: none;
            border: none;
            color: red;
            font-size: 25px;
        }

        .displaycartitem {
            .cart-list {
                max-height: calc(100vh - 290px);
                height: 100%;
                overflow-y: scroll;

                .cart-item {
                    padding: 15px 10px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    -webkit-box-align: start;
                    -ms-flex-align: start;
                    align-items: flex-start;
                    border-bottom: 1px solid var(--orange);

                    .cart-media {
                        position: relative;
                        margin-right: 25px;

                        a {
                            img {
                                width: 80%;
                            }
                        }
                    }

                    .cart-media a img {
                        width: 100px;
                        border-radius: 8px;
                    }

                    .cart-info-group {
                        width: 100%;

                        .cart-info {
                            margin-bottom: 13px;
                            display: flex;
                            justify-content: space-between;

                            .cartItemRemove {
                                svg {
                                    color: red;
                                    font-size: 20px;
                                }
                            }

                            h6 {
                                font-weight: 400;
                                text-transform: capitalize;
                            }

                            p {
                                font-size: 14px;
                            }
                        }

                        .cart-action-group {
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            -webkit-box-pack: center;
                            -ms-flex-pack: center;
                            justify-content: center;
                            -webkit-box-pack: justify;
                            -ms-flex-pack: justify;
                            justify-content: space-between;

                            .product-action {
                                display: -webkit-box;
                                display: -ms-flexbox;
                                display: flex;
                                display: -webkit-box;
                                display: -ms-flexbox;
                                -webkit-box-align: center;
                                -ms-flex-align: center;
                                align-items: center;
                                -webkit-box-pack: center;
                                -ms-flex-pack: center;
                                justify-content: center;

                                button {
                                    border: none;

                                    svg {
                                        width: 25px;
                                        height: 25px;
                                        color: var(--white);
                                        background: var(--orange);
                                    }
                                }

                                input {
                                    width: 50px;
                                    height: 25px;
                                    font-size: 14px;
                                    border-radius: 5px;
                                    color: var(--black);
                                    margin: 0px 10px;
                                    text-align: center;
                                    background: #f5f5f5;
                                    border: none;
                                }
                            }
                        }
                    }

                }
            }

            .cart-footer {
                padding: 20px 15px 0px;
                text-align: center;
                -webkit-box-shadow: 0px -3px 7px 0px rgba(0, 0, 0, 0.08);
                box-shadow: 0px -3px 7px 0px rgba(0, 0, 0, 0.08);
            }

            .cart-checkout-btn {
                padding: 10px 0px;
                border-radius: 8px;
                background: var(--orange);
                text-decoration: none;
                color: var(--white);
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                transition: all linear 0.3s;
                -webkit-transition: all linear 0.3s;
                -moz-transition: all linear 0.3s;
                -ms-transition: all linear 0.3s;
                -o-transition: all linear 0.3s;
            }
        }
    }
}

// =================== End Mobile Sticky ==================
// =================== Start Testimonial ================
.htestmonial {
    margin: 10px 5px;


    .single-reviews-box {
        padding: 0 30px 30px 30px;
        margin-bottom: 30px;
        margin-top: 25px;
        border-radius: 10px;
        -webkit-transition: all ease 0.5s;
        transition: all ease 0.5s;
        height: 350px;
        box-shadow: 0 2px 6px 0 rgba(218, 225, 255, 0.85), 0 5px 8px 0 rgba(214, 188, 238, 0.95);
    }

    .single-reviews-lading {
        padding: unset !important;
        margin-top: unset !important;
        height: unset !important;
    }

    .single-reviews-box .reviews-top-content {
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid #eeeeee;
        height: 350px;

        @include md {
            height: 260px;
        }
    }

    .reviewTop {
        padding-bottom: unset !important;
        margin-bottom: unset !important;
        height: unset !important;
    }

    .single-reviews-box .reviews-top-content .client-img {
        position: relative;
        top: -10px;
    }

    .single-reviews-box .reviews-top-content .client-img img {
        width: auto;
    }

    .single-reviews-box .reviews-bottom-content h3 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .single-reviews-box .reviews-bottom-content ul {
        padding-left: 0;

        svg {
            color: #ffc107;
            font-size: 20px;
        }
    }
}

// =================== End Testimonial ==================
// =================== Order Confirmation ==================
.orderConfirms {
    margin: auto;
    box-shadow: 0 2px 6px 0 rgba(218, 225, 255, 0.85), 0 5px 8px 0 rgba(214, 188, 238, 0.95);
    width: unset;

    @include md {
        width: 800px;
    }

    .orderHeader {
        background: #f4f4f4;
        color: var(--black);
        text-align: center;
        padding: 20px 10px 20px;

        h3 {
            font-size: 40px;
        }
    }

    .orderBody {
        background: #fff;

        svg {
            color: green;
            color: green;
            font-size: 50px;
            text-align: center;
            margin: auto;
            display: flex;
        }

        h2 {
            font-weight: 800;
            text-align: center;
            color: var(--black);
        }

        .orderDetail {
            padding: 0px 10px 0px 10px;
            float: unset;

            @include md {
                float: right;
            }

            div {
                text-align: center;
                display: flex;
            }
        }

        .orderBodyBottom {
            padding: 0px 10px;

            p {
                margin-bottom: unset;
            }
        }
    }

    .orderFooter {
        background: #f4f4f4;
        color: var(--black);
        text-align: center;
        padding: 20px 10px 20px;

        h3 {
            font-size: 40px;
        }

        .shopNow {
            text-decoration: none;
            color: var(--white);
            padding: 10px 20px;
            background: var(--black);
            margin-top: 10px;
            border-radius: 5px;
        }

        .orderFooterBootm {
            p {
                color: var(--black);

                @include md {
                    font-size: 18px !important;
                }

                a {
                    text-decoration: none;
                    color: var(--black);
                }
            }
        }
    }
}

.disabledbtn {
    background: #f5efef !important;
    color: #07273c !important;
}

.btn-orderDetails {
    font-size: 25px;
    text-align: center;
    color: var(--orange);
}

.orderInvoiceDetails {
    background: var(--white);
    padding: 30px 20px 10px;
    box-shadow: 0 2px 6px 0 rgba(218, 225, 259, 0.85), 0 5px 8px 0 rgba(214, 188, 238, 0.95);
}

.companyAddress {
    ul {
        li {
            .footerConnectIcon {
                font-size: 35px;
                color: var(--orange);
                margin-right: 10px;
            }

            .footerConnectIcons {
                font-size: 25px;
                color: var(--orange);
                margin-right: 10px;
            }
        }
    }

}

.invoicename {
    color: var(--black);
    font-size: 35px;
}

.billingAddress {
    span {
        background: #ecedf173;
        text-align: center;
        padding: 0px 200px;
        color: #000;
    }

    p {
        width: 400px;
    }
}

.billingAddresss {
    span {
        background: #ecedf173;
        text-align: center;
        padding: 0px 200px;
        color: #000;
        float: right;
    }
}

.billingAddresss {
    text-align: center;
}

.amountDetails {
    width: 250px;
    float: right;

    .amountDetail {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }
}

.thankyousection {
    display: flex;
    justify-content: space-between;

    .barcodesection {
        img {
            width: 100px;
        }
    }
}

.btnPrint {
    float: right;
    top: -30px;
    position: relative;
    border: none;
    background: none;
}

.billingAddress {
    p {
        line-height: 1.5 !important;
        margin-bottom: 5px !important;
    }
}

.productSticky {
    @include md {
        display: none;
    }

    .productSticky {
        position: fixed;
        bottom: 55px;
        padding: 10px;
        left: 0px;
        width: 100%;
        z-index: 3;
        background: var(--white);
        box-shadow: 0px -5px 15px 0px rgba(0, 0, 0, 0.1);
        display: flex !important;
        justify-content: space-evenly;
        display: block;

        .addToCart {
            background: var(--white);
            color: var(--black);
            border: 1px solid var(--black);
            width: 48%;
            text-align: center;
            padding: 5px 20px;
            border-radius: 10px;
        }

        .buyItNow {
            background-color: var(--black);
            color: var(--white);
            width: 48%;
            text-align: center;
            padding: 5px 20px;
            border-radius: 10px;
            border: 1px solid var(--orange);
        }
    }
}

.lockicon {
    display: flex;
    margin-top: 10px;

    svg {
        margin-right: 10px;
    }
}

.secure-payment-icon {
    img {
        width: 100%;
    }
}

.rating {
    svg {
        color: #fbcd0a;
    }
}

.footer-text {
    padding: 10px 0px;
}

.footerContent {
    margin-bottom: 10px;
}

.readMoreText {
    display: flex;
    border: none;
    background: none;
    margin: auto;
}

.heroBannerDescktop {
    display: none !important;

    @include md {
        display: block !important;
    }
}

.heroBannerMobile {
    display: block !important;

    @include md {
        display: none !important;
    }
}

.active {
    color: var(--orange) !important;
    font-weight: 600;
}

.loadingImg {
    display: flex;
    justify-content: center;

    img {
        width: 50%;
    }
}

.mainBanner {
    width: 100%;
    height: 520px;
    margin: auto;

    @include md {
        width: 100%;
        height: 670px;
        margin: auto;
    }
}

.bestSellerSection {
    width: 100%;
    height: 100%;
    margin: auto;
    margin-bottom: 20px;

    @include md {
        width: 1350px;
        height: 100%;
        margin: auto;
    }

    .appSinglePricingCard {
        width: 100%;
        height: 545px;
        margin: auto;

        @include md {
            width: 95%;
            height: 465px;
            margin: auto;
        }

        .productImg {
            width: 250px;
            height: 235px;

            @include md {
                width: 100%;
                height: 310px;
            }
        }
    }
}

.healthCareSection {
    width: 100%;
    height: 100%;
    margin: auto;
    background: var(--white);

    @include md {
        width: 100%;
        height: 100%;
        margin: auto;
        background: var(--white);
    }
}

.topSellingSection {
    .contentWrapper {
        padding: 0px 10px !important;

        @include md {
            padding: 0px 20px;
        }
    }

    .topSellingCart {
        width: 100%;
        height: 640px;
        margin: auto;

        @include md {
            width: 100%;
            height: 340px;
            margin: auto;
        }
    }
}

.authenticProduct {
    width: 100%;
    margin: auto;

    @include md {
        width: 100%;
        margin: auto;
    }
}

.comboPack {
    width: 100%;
    height: 750px;
    margin: auto;

    @include md {
        width: 100%;
        height: 100%;
        margin: auto;
    }

    .productImg {
        width: 320px;
        height: 310px;
        text-align: center;

        @include md {
            width: 365px;
            height: 365px;
            text-align: center;
        }
    }
}

.testimonial {
    width: 100%;
    height: 800px;
    margin: auto;
    background: var(--white);

    @include md {
        width: 100%;
        height: 525px;
        margin: auto;
        background: var(--white);
    }

    .htestmonial {
        .single-reviews-box {
            width: 100%;
            height: 390px;
            margin: auto;

            @include md {
                width: 100%;
                height: 350px;
                margin: auto;
            }
        }
    }
}

.homeBlog {
    width: 100%;
    height: 550px;
    margin: auto;

    @include md {
        width: 100%;
        height: 590px;
        margin: auto;
    }
}

.footerContent {
    width: 100%;
    height: 100%;
    margin: auto;
    margin-bottom: 10px;

    @include md {
        width: 100%;
        height: 100%;
        margin: auto;
        margin-bottom: 10px;
    }
}

.newsLetter {
    width: 100%;
    height: 400px;
    margin: auto;

    @include md {
        width: 100%;
        height: 400px;
        margin: auto;
    }
}

.productLandingDetailsSection {
    width: 100%;
    height: unset !important;

    @include md {
        width: 100%;
        height: unset !important;
    }

    .productLandingBanner {
        width: 100%;
        height: unset !important;
        margin: unset !important;

        @include md {
            width: 50%;
        }

        .slider {
            img {
                width: 100%;
                height: unset !important;

                @include md {
                    width: 100%;
                    height: 635px;
                }
            }
        }
    }

    .productLangDetail {
        width: 100%;
        height: unset !important;
        margin: unset !important;

        @include md {
            width: 50%;
        }
    }

    .reviewsBottomContent {
        height: unset !important;
    }
}

.productDetailsSection {
    width: 100%;
    --bs-gutter-x: unset !important;

    @include md {
        width: 100%;
    }

    .productBanner {
        width: 100%;

        @include md {
            width: 50%;
        }

        .slider {
            img {
                width: 100%;

                @include md {
                    width: 100%;
                }
            }
        }

        .thumbs {
            img {
                width: 100%;
                height: 80px;
            }
        }
    }

    .productContDetail {
        width: 100%;

        @include md {
            width: 50%;
        }

        h1 {
            width: 100%;

            @include md {
                width: 100%;
            }
        }

        .reviewsBottomContent {
            width: 100%;

            @include md {
                width: 100%;
            }
        }
    }
}

.productSection {
    .productView {
        width: 100%;
        height: auto;
        margin: auto;

        @include md {
            width: 100%;
            height: 100%;
            margin: auto;
        }

        .appSinglePricingCard {
            width: 100%;
            margin: auto;
            margin-bottom: 15px;

            @include md {
                width: 100%;
                margin: auto;
                margin-bottom: 15px;
            }

            .productImg {
                width: 100%;
                height: 100%;

                @include md {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.productLeftView {
    .appSinglePricingCard {
        height: 290px !important;

        .productImg {
            width: 30% !important;
            height: unset !important;

            img {
                width: 90% !important;
            }
        }
    }
}

// =================== End Order Confirmation ==================
// =================== Start Landing Page ==================
.landinPpaymentType {
    display: flex;
    gap: 6px;
    margin-top: 32px;

    @include md {
        gap: 20px;
    }

    .paymentmethod {
        input {
            margin-right: 5px;
        }
    }
}

.landinPpaymentType {
    .securePayment {
        padding: 0px !important;
        background: #fff;
        // box-shadow: 0 0 4px 0 rgba(0, 0, 0, .3);
        margin-top: 10px;

        @include md {
            margin-top: unset;
            width: 100%;
            text-align: center;
            padding: 5px 5px 5px;
        }

        h6 {
            text-align: center;
            font-size: .8rem !important;
            font-weight: 400 !important;

            @include md {
                font-size: 1rem !important;
            }
        }

        p {
            text-align: center;
            letter-spacing: 0.3px;
            font-size: 17px !important;
            line-height: 1.5;
        }
    }
}

.overviewDetails {
    .heading {
        &:after {
            background: var(--green1);
            content: "";
            display: block;
            width: 250px;
            height: 2px;
            position: relative;
        }

        @include md {
            &:after {
                background: var(--green1);
                content: "";
                display: block;
                width: 250px;
                height: 2px;
                position: relative;
            }
        }
    }

    .processBox {
        box-shadow: none;
    }
}

.keybenefits {
    .heading {
        margin-bottom: 10px;

        &:after {
            background: var(--green1);
            content: "";
            display: block;
            width: 250px;
            height: 2px;
            position: relative;
        }

        @include md {
            &:after {
                background: var(--green1);
                content: "";
                display: block;
                width: 250px;
                height: 2px;
                position: relative;
            }
        }
    }

    .keybenefitscard {
        @include md {
            margin: 10px;
            display: flex;
        }

        .imgSection {
            width: 100%;

            @include md {
                margin-right: 40px;
                margin-top: 70px;
                width: 25%;
            }

            img {
                width: 100%;
            }

            @include md {
                margin-top: unset;
            }
        }

        .keyBenefitsDescp {
            margin-top: 10px;

            @include md {
                width: 75%;
            }
        }
    }
}

.Howtouse {
    .heading {
        margin-bottom: 10px;

        &::after {
            background: var(--green1);
            content: "";
            display: block;
            width: 250px;
            height: 2px;
            position: relative;
        }

        @include md {
            &::after {
                background: var(--green1);
                content: "";
                display: block;
                width: 250px;
                height: 2px;
                position: relative;
            }
        }
    }

    .howToUseCard {
        display: flex;

        .howImage {
            margin: 0 10px 0px 0px;
            width: 20%;

            @include md {
                margin-top: unset;
            }

            img {
                width: 100%;

                @include md {
                    width: 80%;
                }
            }
        }

        .howDesc {
            margin: auto;
            width: 80%;
        }
    }
}

.lpIngredient {
    width: 100%;

    @include md {
        width: 100%;
    }

    .heading {
        &:after {
            background: var(--green1);
            content: "";
            display: block;
            width: 250px;
            height: 2px;
            position: relative;
        }

        @include md {
            &:after {
                background: var(--green1);
                content: "";
                display: block;
                width: 250px;
                height: 2px;
                position: relative;
            }
        }
    }

    .lpIngredientcard {
        .imgSection {
            img {
                width: 60%;
                display: flex;
                margin: auto;
            }
        }
    }
}

.productSpece {
    .heading {
        margin-bottom: 10px;

        &:after {
            background: var(--green1);
            content: "";
            display: block;
            width: 250px;
            height: 2px;
            position: relative;
        }

        @include md {
            &:after {
                background: var(--green1);
                content: "";
                display: block;
                width: 250px;
                height: 2px;
                position: relative;
            }
        }
    }
}

.table>:not(caption)>*>* {
    padding: 10px !important;
    border: none !important;
    font-weight: 400;
}

.lpFaq {
    .heading {
        margin-bottom: 10px;

        &:after {
            background: var(--green1);
            content: "";
            display: block;
            width: 250px;
            height: 2px;
            position: relative;
        }

        @include md {
            &:after {
                background: var(--green1);
                content: "";
                display: block;
                width: 250px;
                height: 2px;
                position: relative;
            }
        }
    }

    .accordion__button {
        background-color: #f4f4f4;
        color: #444;
        cursor: pointer;
        padding: 18px;
        width: 100%;
        text-align: left;
        border: none;
    }
}

.lpWhyuse {
    .heading {
        margin-bottom: 10px;

        &:after {
            background: var(--green1);
            content: "";
            display: block;
            width: 250px;
            height: 2px;
            position: relative;
        }

        @include md {
            &:after {
                background: var(--green1);
                content: "";
                display: block;
                width: 250px;
                height: 2px;
                position: relative;
            }
        }
    }

    .lpWhyUseMain {
        display: flex;
        gap: 10px;

        .whyCard {
            width: 100px;

            @include md {
                width: unset;
            }

            img {
                width: 100%;

                @include md {
                    width: unset;
                }
            }

            .whyDesc {
                h4 {
                    font-size: 10px;

                    @include md {
                        font-size: unset;
                    }
                }

                p {
                    font-size: 10px !important;
                    font-weight: 600;

                    @include md {
                        font-size: 13px !important;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

.lpReviews {
    .heading {
        &:after {
            background: var(--green1);
            content: "";
            display: block;
            width: 250px;
            height: 2px;
            position: relative;
        }

        @include md {
            &:after {
                background: var(--green1);
                content: "";
                display: block;
                width: 250px;
                height: 2px;
                position: relative;
            }
        }
    }

    .htestmonial {
        .single-reviews-box {
            padding: 0 30px 30px 30px;
            margin-bottom: 30px;
            margin-top: 25px;
            border-radius: 10px;
            transition: all ease 0.5s;
            height: 350px;
            box-shadow: none;
        }
    }
}

.lp-discount {
    font-size: 15px;
    color: #008000;
    height: 25px;
    margin-left: 10px;
    padding: 2px 10px 2px 10px;
    margin-top: 10px !important;
}

.mob {
    display: block;

    @include md {
        display: none;
    }
}

.fact-area {
    width: 100%;
    position: fixed;
    z-index: 999;
    bottom: 0;
    background: #f2f2f2;
    border-top: #dbdbdb solid 3px;
}

.sticky-title {
    font-weight: 600;
    letter-spacing: 0.6px;
    font-size: 1rem !important;
    color: rgba(0, 0, 0, 0.8117647059) !important;
}

.sticky-titles {
    color: var(--black);
    font-size: 25px;
}

.productname-sticky {
    font-size: 1.2rem !important;
    color: rgba(0, 0, 0, 0.8117647059) !important;
    font-weight: 600 !important;
    letter-spacing: 0.5px;
    font-family: unset !important;
}

.sticky-buy-now {
    background: #e94500;
    border-radius: 5px;
    color: #fff !important;
    font-size: 15px;
    padding: 5px 10px;
    text-decoration: none;
    margin: auto;
    margin-right: unset;

    button {
        background: none;
        border: none;
        color: #fff;
    }

    svg {
        margin-right: 5px;
        margin-top: -1px;
        font-size: 18px;
    }
}

.callNow {
    margin-left: 20px;

    svg {
        font-size: 30px;
    }
}

.displayspeachtext {
    display: none;
}

.landingBtn {
    button {
        width: 100% !important;
        font-size: 15px !important;
    }
}

.share-now {
    padding: 10px 12px;
    color: #f44336 !important;
    border-radius: 50px;
    border: 2px solid #07273c;

    svg {
        font-size: 18px;
        margin-top: -7px;
    }
}

.lpProductDetailsSection {
    .product-details ul {
        li {
            font-size: 14px !important;
            display: flex;
        }
    }
}

.breadcrumbs {
    .items {
        font-size: 1rem;
    }
}

.mobileHeaderMenuIcons {
    margin-top: 20px;
    font-size: 20px;
    display: flex;

    .headerLogin {
        color: var(--white);
        margin-right: 15px;
        text-decoration: none;

    }

    .mobileBasket {
        margin-right: 10px;

        span {
            border: 1px solid red;
            border-radius: 50px;
            padding: 0px 7px;
            font-size: 15px;
            margin-top: -4px !important;
            position: absolute;
        }
    }
}

.descktopview {
    display: none;

    @include md {
        display: block;
    }
}

.ayurvedicProduct {
    .descktopview {
        ul {
            margin-bottom: 20px !important;

            @include md {
                margin-bottom: unset;
            }
        }
    }
}

.mobileview {
    display: block;

    @include md {
        display: none;
    }
}

.mobileviewBestSeller {
    display: flex;
    gap: 5px;
    margin-left: -5px;
    margin-right: -5px;
    overflow: scroll;

    .appSinglePricingCard {
        width: 75%;
        height: 430px;
        margin: auto;
        padding: 5px;

        .pricingList {
            a {
                text-decoration: none;
            }
        }
    }
}

.mobileviewTestimonial {
    display: flex;
    gap: 5px;
    margin-left: -5px;
    margin-right: -5px;
    overflow: scroll;

    .htestmonial {
        .single-reviews-box {
            padding: 0 10px 10px 10px;
            margin-bottom: 10px;
            margin-top: 25px;
            border-radius: 10px;
            transition: all ease 0.5s;
            height: 465px;
            box-shadow: 0 2px 6px 0 rgba(218, 225, 255, 0.85), 0 5px 8px 0 rgba(214, 188, 238, 0.95);
            width: 280px;
        }
    }
}

.mobileviewHomeBlog {
    display: flex;
    gap: 5px;
    margin-left: -5px;
    overflow: scroll;
    position: absolute;
    left: 5px;
    margin-right: -5px;

    .app-single-blog-card {
        box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        padding: 10px;
        background: #fff;
        width: 310px;
        margin-right: unset !important;
    }
}

.mobileMenuItems {
    .cart-btn {
        background: none;
        color: var(--orange);
        font-size: 25px;
        position: absolute;
        right: 60px;
        top: 30px;
        border: none;
    }
}

.healthCareSection {
    .appContainer {
        position: relative;
        top: 10px;
    }
}

.authenticProduct {
    .appContainer {
        position: relative;
        top: 10px;
    }
}

.testimonial {
    .appContainer {
        position: relative;
        top: 10px;
    }
}

.awssld {
    height: 95% !important;
}

.mobileviewBestSeller {
    .productNameMobile {
        text-decoration: none;

        h4 {
            height: 40px;
            margin-top: 30px;

            @include md {
                margin-top: unset !important;
            }
        }
    }

    .pricingList {
        margin-bottom: unset !important;
    }
}

.awssld__container figure,
.awssld__content,
.awssld__box {
    width: 100% !important;
    height: 100% !important;
}

.accountSection {
    display: flex;
    border: 1px solid #efe2e2;
    margin-top: 10px;
    margin-bottom: 30px;
    height: 150px;
    border-radius: 15px;

    &:hover {
        background: #eee;
    }

    .imgSection {
        width: 30%;
        margin: auto;

        img {
            width: 100%;
        }
    }

    .contentSection {
        width: 70%;
        margin-top: 20px;
        margin-left: 15px;

        h4 {
            font-size: 20px;
            font-weight: 500;
            color: #111;
        }

        span {
            font-size: 18px;
            color: #565959;

            a {
                color: #565959;
            }
        }
    }
}

.accountBodySection {
    a {
        text-decoration: none;
    }
}

.myOrderDetails {
    .orderDetails {
        background: #e2e6ed;
        padding: 10px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border: 1px solid #cbced3;

        .orderPacedDetails {
            display: unset !important;

            @include md {
                display: flex !important;
                justify-content: space-between;
            }

            .orderPlaced,
            .orderTotal,
            .orderShipTo,
            .orderPaymentMethod {
                display: flex;
                justify-content: space-between;

                @include md {
                    display: flex;
                    flex-direction: column;
                }

                .orderType {
                    text-transform: uppercase;
                }

                .orderTypeDetails {
                    font-size: 15px;
                    font-weight: 500;
                    color: var(--black);
                    font-weight: 600;

                    svg {
                        margin-top: -5px;
                        margin-right: 5px;
                    }
                }

                .orderShipName {
                    color: var(--orange);
                }

                .orderPaymentDetails {
                    color: var(--black);
                    font-weight: 600;

                    svg {
                        margin-top: -5px;
                        margin-right: 5px;
                    }
                }
            }
        }

        .orderId {
            float: left;

            @include md {
                float: right;
            }

            a {
                text-decoration: none;
                color: var(--orange);
            }
        }
    }

    .shipProductDetails {
        background: #fff;
        padding: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-left: 1px solid #cbced3;
        border-right: 1px solid #cbced3;
        border-bottom: 1px solid #cbced3;

        .productImg {
            width: 100%;

            @include md {
                width: 50%;
            }

            img {
                width: 100%;
            }
        }

        .productNameBtn {
            a {
                text-decoration: none;
                color: var(--black);
            }

            .orderAddress {
                display: flex;
                gap: 5px;

                svg {
                    font-size: 20px;
                }

                p {
                    font-size: 15px !important;
                }
            }

            .orderMobile {
                display: flex;
                gap: 5px;

                svg {
                    font-size: 20px;
                }

                p {
                    font-size: 15px;
                }
            }

            .buyAgain {
                button {
                    padding: 7px 15px 7px 15px;
                    background: var(--orange);
                    color: var(--white);
                    border: none;
                    border-radius: 5px;
                }
            }
        }

        .getProductSupport {
            margin-top: 22%;
            text-align: center;

            a {
                text-decoration: none;
                padding: 5px 5px 5px 5px;
                background: var(--orange2);
                color: var(--white);
                border-radius: 5px;

                svg {
                    margin-top: -5px;
                    margin-right: 5px;
                }
            }
        }
    }
}


.orderCard {
    margin-top: 10px;
}

.topPriceSection {
    margin-bottom: unset;

    @include md {
        margin-bottom: 15px;
    }
}

.account-card {
    background: #fff;
    border-radius: 8px;
    margin-bottom: 30px;
    padding: 0 30px 30px;

    @include md {
        overflow-y: unset;
    }

    overflow-y: scroll;
}

.account-title {
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    justify-content: center;
    justify-content: space-between;
    margin-bottom: 25px;
    padding: 18px 0;
    position: relative;
}

.account-title:before {
    background: var(--orange);
    bottom: -2px;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    width: 50px;
}

.account-title h4 {
    font-weight: 600;
    text-transform: capitalize;
}

.table-image img {
    height: 100px;
    width: auto;
}

.table-name {
    text-transform: capitalize;
    white-space: nowrap;
}

.table-list {
    width: 100%;
}

tbody tr td {
    border-right: 1px solid #e8e8e8;
    padding: 12px 20px;
    text-align: center;
}

.cart-action-group {
    -webkit-box-pack: justify;
    align-items: center;
    display: flex;
    justify-content: center;
}

.cart-action-group .product-action {
    display: flex;
}

.cart-action-group .product-action input {
    background: #f5f5f5;
    border-radius: 5px;
    color: #a4957d;
    font-size: 14px;
    height: 30px;
    width: 45px;
    border: none;
}

.product-action input {
    background: #a4957d;
    border-radius: 6px;
    color: #fff;
    font-size: 15px;
    margin: 0 5px;
    padding: 6px 0;
    text-align: center;
    text-shadow: var(--text-gray);
    width: 100%;
}

thead tr th {
    color: var(--black);
    border-right: 1px solid #e8e8e8;
    font-size: 17px;
    font-weight: 500;
    padding: 12px 20px;
    text-align: center;
    text-transform: capitalize;
    white-space: nowrap;
}

thead tr {
    border-bottom: 1px solid var(--black);
}

.action-minus,
.action-plus {
    border: none;
    background: var(--black);
    padding: 3px;
    border-radius: 5px;
    color: var(--white);
}

.table-action {
    .trash {
        border: none;
        color: red;
        padding: 5px;
        border-radius: 5px;
    }
}

.btn-checkout {
    margin-top: 15px;
    width: 100%;
    text-align: center;

    .cart-checkout-btn {
        text-decoration: none;
        background: var(--black);
        color: var(--white);
        padding: 7px 12px;
        border-radius: 5px;
    }
}

.spetbar-card {
    background: #fff;
    border-radius: 8px;
    margin-bottom: 10px;
}

.StepperContainer-0-2-1 {
    padding: 10px !important;
}

.account-content {
    .billingsAddress {
        display: flex;

        svg {
            margin-top: 3px;
            margin-right: 5px;
        }

        .locationIcon {
            font-size: 25px;
            margin-left: -4px;
        }
    }
}

.card-summary {
    background: #fff;
    border-radius: 8px;
    margin-bottom: 10px;

    .card-summary-details {
        padding: 10px 10px;

        .cartImg {
            img {
                width: 100%;
            }
        }
    }
}

.orderSummaryDetailsPrice {
    p {
        margin-bottom: unset;
    }

    .cart-action-group {
        justify-content: start;
    }

    .btntrash {
        float: right;
        border: none;
        color: red;
        padding: 5px;

    }
}

.price-summary {
    .price-summary-details {
        display: flex;
        justify-content: space-between;

        .priceCharge {
            color: var(--green);
        }

        span {
            margin-top: 5px;
        }
    }

    .total-amount-details {
        border-top: 1px dashed var(--text-gray);
        margin-top: 20px;

        span {
            margin-top: 10px;
        }
    }

    .price-summary-payment {
        width: 100%;
        background: var(--black);
        padding: 5px 5px 5px;
        margin-top: 10px;
        border-radius: 5px;
        text-align: center;

        button {
            color: var(--white);
            background: none;
            border: none;
        }
    }
}

.account-title {
    a {
        text-decoration: none;
    }
}

.checRresendMobileTop {
    text-decoration: none;
    float: right;
    margin-top: 5px;
    margin-bottom: 5px;
}

.StepButton-0-2-4 {
    width: 2em;
    border: none;
    cursor: pointer;
    height: 2em;
    margin: 0px;
    display: flex;
    padding: 0px;
    font-size: 1em;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: rgb(224, 224, 224);
}

.StepButton-0-2-4.active {
    background-color: var(--orange) !important;

    span {
        color: var(--white) !important;
    }
}

.ConnectorContainer-0-2-20 {
    top: calc((2em - 1px) / 2);
    left: calc((-50% + 2em) - 8px);
    right: calc((50% + 2em) - 8px);
    position: absolute;

    .Connector-0-2-21 {
        display: block;
        border-color: rgb(189, 189, 189);
        border-top-style: solid;
        border-top-width: 1px;
    }
}

.StepButton-0-2-4.completed {
    background-color: var(--green) !important;

    span {
        color: var(--white);
    }
}

.StepContainer-0-2-2 {
    flex: 1;
    position: relative;
    padding-left: 8px;
    padding-right: 8px;
}

.StepperContainer-0-2-1 {
    display: flex;
    padding: 24px;
    align-items: flex-start;
    font-family: unset;
    flex-direction: row;
}

.Connector-d3-0-2-23 {
    display: block !important;
    border-color: rgb(189, 189, 189) !important;
    border-top-style: solid !important;
    border-top-width: 1px !important;
}

.deliverynames {
    display: unset;

    @include md {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }

    .deliverynumber {
        margin-left: 10px;
    }

    .deliveryBtn {
        button {
            border: none;
            padding: 2px 5px 2px 6px;
            border-radius: 5px;
        }

        .btnEdit {
            margin-right: 10px;
            background: var(--black);
            color: var(--white);
        }

        .btnRemove {
            background: var(--orange);
            color: var(--white);
        }
    }
}

.adeliveryaddressbtn {
    button {
        border: none;
        border-radius: 5px;
        background: var(--orange);
        padding: 8px;
        color: var(--white);
        margin-top: 10px;
    }
}

.ddeliveryBottom {
    border-bottom: 1px dashed var(--text-gray);
}

.productPageCard {
    .productnames {
        height: 40px !important;

        @include md {
            height: 40px !important;
        }
    }

    .productImg {
        margin-bottom: 10px !important;
    }

    .productContentList {
        .pricingList {
            margin-bottom: unset !important;
        }
    }
}

.norderimg {
    margin: auto;
    display: flex;
    justify-content: center;

    img {
        width: 50%;
    }
}

.continue-shoppingbtn {
    padding: 5px;
    text-decoration: none;
    text-align: center;
    display: flex;
    margin: auto;
    justify-content: center;
    background: var(--black);
    width: 50%;
    margin-top: 10px;
    color: var(--white);
    border-radius: 5px;

    &:hover {
        color: var(--white);
    }
}

.deskplus,
.deskminus {
    background: none !important;
}

.ayurvedicProduct {
    background: var(--white);
    width: 100%;

    @include md {
        height: 100%;
        margin-bottom: 10px;
    }
}

.ayurvedicProduct {
    h2 {
        position: relative;
        top: 8px;
    }
}

.callnowlanding {
    text-decoration: none;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 2px;
    background: var(--orange2);
    font-size: 25px;
    color: var(--white);

    &:hover {
        color: var(--white);
    }

    svg {
        margin-top: 8px;
        margin-right: 5px;
    }
}

.pricingList {
    h4 {
        height: 35px;
    }
}

.ingredient-text-view {
    height: 370px !important;
    margin-top: -35px;
}

.ingredientcard {
    box-shadow: 0 2px 6px 0 rgba(218, 225, 255, 0.85), 0 5px 8px 0 rgba(214, 188, 238, 0.95);
    padding: 10px;
    margin: 5px;
    height: 300px;
    text-align: center;

    @include md {
        width: 330px;
    }
}

.lpIngredientcard {
    box-shadow: 0 2px 6px 0 rgba(218, 225, 255, 0.85), 0 5px 8px 0 rgba(214, 188, 238, 0.95);
    padding: 10px;
    margin: 5px;
    height: 390px;
    text-align: center;

    @include md {
        width: 330px;
    }
}

.ltestmonial {
    box-shadow: 0 2px 6px 0 rgba(218, 225, 255, 0.85), 0 5px 8px 0 rgba(214, 188, 238, 0.95);
    padding: 10px;
    margin: 5px;
    height: 400px;

    @include md {
        width: 325px;
    }
}

.heroBannerDescktop {
    .deskbanner {
        width: 100%;
    }
}

// =================== End Start Landing Page ==================
// Start Tooltip
.styles-module_dark__xNqje {
    background: var(--white) !important;
    color: var(--black) !important;
    z-index: 11;
}

.styles-module_tooltip__mnnfp {
    padding: 0px 0px !important;
    width: 200px !important;
}

.tooltipmenulink {
    padding: unset !important;
    display: flex;
    flex-direction: column !important;
    margin-top: unset !important;

    a {
        color: var(--black) !important;
        cursor: pointer !important;
        text-decoration: none;
        font-size: 15px !important;
        padding: 5px 10px;

        &:hover {
            background-color: var(--orange);
            color: var(--white) !important;
            border-radius: 3px;
        }
    }
}

.core-styles-module_tooltip__3vRRp {
    pointer-events: unset !important;
}

.breadcrumbss {
    margin: unset !important;
}

.ingredientList {
    text-align: center;
    border: 0 solid transparent;
    margin-bottom: 1.5rem;
    box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);
    background: var(--white);
    border-radius: 10px;
    padding: 5px;

    a {
        text-decoration: none;

        img {
            width: 100%;
        }

        h4 {
            margin-top: 20px;
            text-transform: capitalize;
        }

        p {
            text-align: left !important;
        }
    }
}

// End Tooltip
.awssld__content {
    display: unset !important;
    background-color: unset !important;
}

.awssld__content img {
    width: 100%;
}

.sticky-landing-page {
    z-index: 11111 !important;
}

.productdetailsdesc {
    text-align: unset !important;
}

.lpIngredientDescp {
    p {
        text-align: unset !important;
    }
}

// Address Section
.addressSection {
    background: #fff;
    box-shadow: 0 2px 6px 0 rgba(218, 225, 255, 0.85), 0 5px 8px 0 rgba(214, 188, 238, 0.95);
    padding: 5px 10px 10px 10px;
    border-radius: 5px;
    margin-top: 10px;

    .addressHeading {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ebe5e5;

        svg {
            margin-top: -3px;
        }

        a {
            text-decoration: none;

            svg {
                margin-top: -5px;
            }
        }

        .btnaddressremove {
            color: red;
            margin-left: 5px;
            border: none;
            background: none;
        }
    }

    .ordertitle {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        padding: 0px 5px 15px 0px;

        .name {
            color: gray;
        }

        .namedetail {
            color: #000;
            font-weight: 500;
        }
    }
}

.addressbodysection {
    margin: 10px 0px 10px 0px;

    .address {
        padding: 5px 10px 5px 0px;
    }
}

.authenticBody {
    display: flex;
    overflow: auto;
    gap: 10px;

    @include md {
        overflow: unset;
    }

    .singleAppFeaturesBox {
        width: 245px;

        @include md {
            width: 320px;
        }

        .featuresContent {
            p {
                text-align: center;
            }

            height: 250px;

            @include md {
                height: unset;
            }
        }
    }
}

.healthCarebody {
    display: flex;
    overflow-x: auto;

    .healthCarebodyimage {
        div {
            width: 250px;
            padding: 0px 10px 10px 0px;
        }
    }
}

.shopbyconcern {
    a {
        img {
            width: 100%;
        }
    }
}

.shopbyconcerntext {
    p {
        padding: 0px 10px;
        text-align: center !important;

        @include md {
            padding: unset;
        }
    }
}

.failedBosySection {
    height: 40vh;
    margin-bottom: 80px;

    @include md {
        margin-bottom: 50px;
    }
}

.failedConfirms {
    width: 400px;
    margin: auto;
    box-shadow: 0 2px 6px 0 rgba(218, 225, 255, 0.85), 0 5px 8px 0 rgba(214, 188, 238, 0.95);

    .orderHeader {
        background: var(--orange);
        color: var(--white);
        text-align: center;
        padding: 20px 10px 20px;
    }
}

.failedsection {
    padding: 15px 11px 0px 10px;

    .failedbody {
        display: flex;
        justify-content: space-between;
    }
}

.btntryagain {
    background: #ff5733;
    text-align: center;
    justify-content: center;
    display: flex;
    text-decoration: none;
    padding: 5px 10px;
    color: #fff;
}

.productnotfound {
    height: 33vh;
}

.btnFilter {
    float: right;
    margin-top: 5px;
}

.filterHeading {
    svg {
        display: block;

        @include md {
            display: none;
        }
    }
}

.filterForm {
    display: none;

    @include md {
        display: block;
    }
}

.lpcontainerbox {
    padding: unset !important;
}

.lpcontainer {
    p {
        text-align: left !important;
    }
}

.stickyprice {
    margin: auto;
    margin-left: unset;
}

.oderhead {
    background-color: var(--black);

    th {
        color: var(--white);
    }
}

.ordertable {
    .orderoimg {
        width: 50px;

        img {
            width: 100%;
        }
    }
}

.ordertbody {
    background-color: var(--white);
}

.sociallink {
    li {
        a {
            color: var(--black) !important;

            :hover {
                color: var(--orange);
            }
        }
    }
}

.btncallnow {
    text-decoration: none;
    background: var(--green);
    border-radius: 5px;
    color: #fff !important;
    font-size: 15px;
    padding: 5px 10px;
    text-decoration: none;
    margin: auto;
    margin-right: unset;
}

.sticky-price {
    color: var(--orange);
    font-weight: 600;
}

.sticycutprice {
    line-height: unset !important;
    margin-bottom: unset !important;
}

.talktoexpert {
    padding: 5px 0px 5px 0px;

    .talktodetail {
        padding: 10px 10px 10px 10px;
        border: 1.5px solid #0e870e;

        @include md {
            padding: 10px 30px 10px 10px;
        }

        .talkimg {
            width: unset;

            @include md {
                width: 400px;
            }

            img {
                width: 100%;
            }
        }

        .drdesc {
            position: relative;

            p {
                margin-bottom: unset !important;
            }

            h3 {
                margin-top: 10px;
                font-weight: 600;
                font-size: 17px !important;
                color: var(--orange);

                @include md {
                    margin-top: unset;
                }
            }

            .talkdrdesc {
                margin-bottom: 20px;
            }

            strong {
                color: var(--black);
            }

            .btntalk {
                border: none;
                background: var(--orange);
                color: var(--white);
                padding: 5px 5px 3px 5px;
                width: 100%;
                margin: auto;
                border-radius: 5px;
                text-transform: uppercase;
                font-size: 15px;

                @include md {
                    bottom: -88px;
                    position: absolute;
                    width: 50%;
                }
            }
        }
    }
}

.modal-title {
    font-size: 1rem;
}

.btnpopup {
    width: 100%;
}

.pricingList {
    a {
        text-decoration: none;
    }
}

.productTopBars {
    background: #fff;
    padding: 10px 0px 0px 0px;
    border-radius: 5px;
    display: block !important;
}

// Header Search Bar 
.headerSearchBar {
    display: none;

    @include md {
        margin: auto;
        width: 70%;
        display: block !important;
    }

    form {
        background-color: #f1f4f6;
        display: flex;
        align-items: center;

        input {
            font-size: 14px;
            line-height: 21px;
            display: block;
            padding: 6px 30px 7px 0;
            border: none;
            outline: 0;
            bottom: 19px;
            box-shadow: none;
            color: #3e3e3e;
            text-indent: 12px;
            height: 40px;
            border-radius: 0 6px 6px 0;
            background-color: #f1f4f6;
            width: calc(100% - 25px);
            display: inline;
        }

        .header_search_icon {
            width: 20px;
            height: 20px;
            cursor: pointer;
            margin-right: 5px;
        }

        .searchiconssubmit {
            border: none;
            color: var(--black);
            background: none;
        }
    }

    ul {
        display: flex;
        justify-content: space-between;
        margin: auto;
        padding: 10px 0px 0px 0px;

        li {
            a {
                color: #212121;
                font-weight: 600;
                letter-spacing: .8px;
                text-decoration: none;
                text-transform: uppercase;
                font-size: 12px;

                &:hover {
                    color: var(--orange);
                }
            }
        }
    }
}

.searchBardiv {
    display: block !important;
    margin: auto;
    width: 100%;

    @include md {

        display: none !important;
    }

    form {
        background-color: #f1f4f6;
        display: flex;
        align-items: center;

        input {
            font-size: 14px;
            line-height: 21px;
            display: block;
            padding: 6px 30px 7px 0;
            border: none;
            outline: 0;
            bottom: 19px;
            box-shadow: none;
            color: #3e3e3e;
            text-indent: 12px;
            height: 40px;
            border-radius: 0 6px 6px 0;
            background-color: #f1f4f6;
            width: calc(100% - 25px);
            display: inline;
        }

        .header_search_icon {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }

        .searchiconssubmit {
            border: none;
            color: var(--black);
            background: none;
        }
    }

    ul {
        display: flex;
        justify-content: space-between;
        margin: auto;
        padding: 10px 0px 0px 0px;

        li {
            a {
                color: #212121;
                font-weight: 600;
                letter-spacing: .8px;
                text-decoration: none;
                text-transform: uppercase;
                font-size: 12px;

                &:hover {
                    color: var(--orange);
                }
            }
        }
    }
}

.catdesps,
.catdespstitle {
    padding: 0px 10px;

    ul {
        margin-left: 40px;
    }
}

.catdespbread {
    margin: 5px 0px -5px 0px !important;

    @include md {
        margin: unset !important;
    }
}

.menuBar {
    background-color: #fff;
    border: 1px solid #dedede;

    ul {
        display: flex;
        justify-content: space-between;
        margin: auto;
        padding: 10px 0px 10px 5px;

        li {
            a {
                color: var(--black);
                font-weight: 600;
                letter-spacing: .8px;
                text-decoration: none;
                text-transform: uppercase;
                font-size: 15px;

                &:hover {
                    color: var(--orange);
                }
            }
        }
    }
}

.psearch {
    display: block;

    @include md {
        display: none;
    }
}

.mobilesearch {
    display: flex;
}

.searchtbn {
    display: flex;
    gap: 15px;

    .micIcons {
        span {
            svg {
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
    }
}

.btnreadmore {
    border: none;
    margin-bottom: 20px;
    background-color: unset;
    color: blue;
    margin-left: 10px;
}

.newlp {
    border-top: 1px solid var(--text-gray);

    .landingbreadcrumbs {
        margin-bottom: 10px;
        margin-top: 5px;

        .nav {
            display: flex;
            text-align: center;
            justify-content: center !important;

            li {
                a {
                    color: var(--black);
                }
            }
        }
    }
}

// New Landing Page
.product-detail-info h1 {
    font-size: 21px !important;
    color: var(--black);
    font-weight: 600;
    line-height: 30px !important;
    margin-top: 0;
}

.text-warning {
    color: #ff9f00 !important;
    font-size: 20px;

    svg {
        margin-top: -5px;
    }
}

.lprating {
    span {
        color: #0aad0a !important;
        font-weight: 500;
    }
}

.lpbenefits {
    span {
        color: #001e2b !important;
        font-weight: 600;
    }
}

.product-detail-info .description_icons {
    flex-wrap: wrap;
    row-gap: 0.55rem;
}

.product-detail-info .wn-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.product-detail-info .desc_icon_box {
    flex: 0 0 50%;
    display: flex;
    align-items: center;
    column-gap: 0.5em;
    padding-right: 0.5em;
    padding-top: 5px;
}

.product-detail-info .desc_icon_box img {
    max-width: 3rem;
}

.text-dark {
    --fc-text-opacity: 1;
    color: var(--black) !important;
}

.text-muted {
    --fc-text-opacity: 1;
    color: var(--text-gray) !important;
}

.text-decoration-line-through {
    text-decoration: line-through !important;
}

.benefits__text {
    h4 {
        color: var(--black);
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: .5rem;
        margin-top: 0;
    }
}

.lpprice {
    display: flex;
    gap: 10px;
}

.product-detail-info .desc_icon_box span {
    line-height: 20px !important;
    font-weight: 500;
    color: #5c6c75 !important;
    font-size: 15px;
    letter-spacing: .6px;
}

.product-detail-info .badge {
    background-color: #ff9f00 !important;
    color: #000 !important;
    border-radius: 50px;
    font-size: 12px !important;
}

.product-detail-info .sf-custom__grid {
    margin: calc(var(--column-gap) / -2) calc(var(--column-gap) / -2);
    display: flex;
}

.product-detail-info .service-icons {
    /* display: flex; */
    flex-direction: column;
    text-align: center;
}

.product-detail-info .service-icons img {
    width: 50%;
    margin-bottom: 0;
}

.product-detail-info .service-icons p {
    letter-spacing: 0 !important;
    line-height: 1.2 !important;
    margin-top: 0 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    text-align: unset !important;
}

.guaranteed {
    img {
        margin-top: -5px;
        margin-right: 5px;
    }
}

.lpcarousel {
    ul {
        .thumb {
            width: 80px !important;
            height: 80px !important;

            @include md {
                width: 128px !important;
                height: 128px !important;
            }
        }
    }
}

/* Marqee */
.homemarq .marquee__text img {
    width: 25px;
    height: auto;
    display: inline-block;
    vertical-align: -8px;
    margin-right: 10px;
}

.homemarq .marquee__text::before {
    display: none
}

.marquee__text img {
    vertical-align: middle;
    padding: 10px 0 0;
    display: block;
}

.marquee__wrapper {
    white-space: nowrap;
    overflow: hidden;
    background-color: none;
    width: 100%;
    color: #000;
    text-transform: uppercase;
    padding: 5px 0;
    border-bottom: 1px solid #dedede;
    border-top: 1px solid #dedede;
}

.marquee {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.marquee__inner {
    -webkit-animation: marquee 15s linear infinite;
    animation: marquee 15s linear infinite;
}

.marquee__text {
    display: inline-block;
    margin-right: 5.3em;
    font-size: 14px;
    line-height: 2.4;
    position: relative;
}

.marquee__text::before {
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    left: -30px;
    background: #000;
    border-radius: 50px;
    top: 15px;
}

.marquee:hover .marquee__inner {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.imgmarq .marquee__text::before {
    display: none
}

@keyframes marquee {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    100% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%)
    }
}

/* Marqee */
.section-tab {
    padding: 0px 0;
    position: sticky;
    top: 0px;
    z-index: 11;
}

.bg-tab {
    background: var(--black);
}

.all-tab>ul {
    display: flex;
    list-style: none;
    left: 0;
    margin: 0;
    padding-left: 0rem;
}

.all-tab>ul>li {
    padding: 14px;
    white-space: nowrap;
    word-break: initial;
}

.all-tab>ul>li>a {
    color: #fff;
    line-height: 1.8;
    font-size: 16px;
    position: relative;
}

.section-all {
    padding: unset !important;

    @include md {
        padding: 50px 0 50px;
    }
}

.t-tittle h2 {
    font-size: 42px !important;
    font-weight: 800;
    text-transform: uppercase;
}

.section-all .key-benefit .use-card {
    background: #e6f2eb;
    padding: 15px 40px;
    border: #f3f3f3 1px solid;
    height: 300px;
}

.section-all .key-benefit .use-card img {
    width: 75%;
    display: block;
    border-radius: 100%;
    overflow: hidden;
    margin: auto;
    border: #f16237 4px solid;
}

.section-all .key-benefit .use-card p {
    font-size: 16px !important;
    color: var(--black);
    line-height: 1.3 !important;
    margin: 0;
    padding-top: 10px;
    text-align: unset !important;
}

.videoTitle {
    letter-spacing: 2.5px !important;
    font-size: 46px !important;
}

.videoContnt {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 1.3 !important;
}

.section-all .key-ingredient .ing-box {
    border-radius: 50px;
    background: #e1e5ee;
    padding: 40px;
}

.section-all .key-ingredient .ing-box img {
    border-radius: 2rem;
    width: 100%;
}

.section-all .key-ingredient .ing-box-text h4 {
    font-weight: 800;
}

.section-all .key-ingredient .ing-box-text p {
    font-size: 14px !important;
    color: var(--black);
    line-height: 1.3 !important;
    margin: 0;
    padding-top: 5px;
    text-align: unset !important;
}

.banner .red-gradient-bg {
    background: linear-gradient(127deg, #B3001B -16.14%, #931F1D 33.43%);
    border-radius: 3.125rem;
}

.banner h2 {
    font-size: 42px !important;
    color: #fff;
    line-height: 45.4px !important;

    @include md {
        line-height: 1.74px;
    }
}

.banner p {
    color: #fff !important;
}

.btn-primary-new {
    cursor: pointer;
    background-color: #ffd814;
    border: none;
    color: var(--black) !important;
    border: 1px solid #fcd200;
    font-weight: 600;
}

.btn-new {
    font-size: 1rem;
    line-height: 1.5;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075);
    color: #fff;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    padding: 1rem 5rem;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border-radius: 10rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;

    @include md {
        width: 50%;
    }
}

.steps .step {
    padding: 20px 0 15px;
}

.steps .step h4 {
    font-size: 26px !important;
    font-weight: 600;
    color: #59702f;
}

.list-inline {
    svg {
        color: #ff9f00 !important;
    }
}

.heading-color {
    svg {
        margin-top: -5px;
        margin-right: 5px;
    }
}

.t-tittle h2 span {
    font-weight: 400 !important;
}

.qus-bg {
    background: #2A324B;
    padding: 50px 0 50px;
    border-radius: 30px;
}

.qus-bg {
    .accordion {
        padding: 0px 10px !important;
    }
}

@media (max-width: 430px) {
    .section-all .key-benefit .use-card {
        height: 220px;
    }
}

@media (max-width: 768px) {
    .section-all .key-benefit .use-card {
        background: #e6f2eb;
        padding: 10px 5px;
        border: #f3f3f3 1px solid;
        height: 220px;
        margin-bottom: 20px;
    }
}

@media (max-width: 768px) {
    .videoTitle {
        letter-spacing: 1.3px !important;
        font-size: 24px !important;
    }

    .section-all .key-ingredient .ing-box {
        border-radius: 30px;
        padding: 15px;
    }
}

@media (max-width: 767px) {
    .t-tittle h2 {
        font-size: 28px !important;
    }
}

// Doctor Start
.doctorContainer {
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    padding: 1em;
    background: #fff;
    width: 100%;
    margin: 0 0 10px;
    box-shadow: 0 2px 6px 0 rgba(218, 225, 255, .851), 1px 2px 4px 0 rgba(194, 186, 201, .949);

    .doctorCard {
        display: flex;
        gap: 10px;

        .doctorImg {
            width: 100px;

            @include md {
                width: 10%;
            }

            img {
                width: 75px;
                border-radius: 8px;

                @include md {
                    width: 100%;
                }
            }
        }

        .doctorBody {
            width: 90%;

            .doctorName {
                color: var(--black);
                font-weight: 600 !important;
                font-size: 20px !important;
                line-height: 23px !important;
                margin: 0;
            }

            .doctorSpc {
                display: flex;
                flex-wrap: wrap;

                @include md {
                    display: flex;
                    flex-wrap: nowrap;
                }

                p {
                    color: #3a698e;
                    margin: 5px 0;
                    font-weight: 600 !important;
                    font-size: 12px !important;
                    display: flex;
                    align-self: left;
                    margin-bottom: 0px !important;
                    text-align: left !important;

                    @include md {
                        font-size: 14px !important;
                    }

                    svg {
                        margin-top: 5px;
                    }
                }
            }

            .doctorQua {
                p {
                    font-size: 12px !important;
                    margin-bottom: 5px !important;
                    color: var(--orange);
                    text-align: left;

                    @include md {
                        font-size: 14px !important;
                    }
                }
            }

            .doctorDegree {
                p {
                    margin-bottom: 5px !important;
                    color: var(--black);
                    font-weight: 500 !important;
                    font-size: 12px !important;

                    @include md {
                        font-size: 14px !important;
                    }
                }
            }

            .doctorLang {
                p {
                    color: var(--green);
                    font-size: 12px !important;
                    align-self: left;

                    @include md {
                        font-size: 14px !important;
                    }
                }
            }

            .doctorDesc {
                p {
                    font-size: 12px !important;
                    align-self: left;
                    text-align: left !important;

                    @include md {
                        font-size: 14px !important;
                    }
                }
            }

            .doctorBtn {
                button {
                    border: none;
                    background-color: var(--black);
                    color: var(--white);
                    padding: 5px 15px 5px 15px;
                    border-radius: 5px;
                    width: 100%;

                    @include md {
                        width: 30%;
                    }
                }
            }
        }
    }
}

.react-calendar {
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    padding: 1em;
    background: #fff;
    width: 100%;
    margin: 0 0 10px;
    box-shadow: 0 2px 6px 0 rgba(218, 225, 255, .851), 1px 2px 4px 0 rgba(194, 186, 201, .949);
}

.react-calendar__tile--active {
    background: var(--green) !important;
    border-radius: 5px;
    font-weight: 600;
    color: white;
}

.timesBody {
    display: flex;
    box-shadow: 0 2px 6px 0 rgba(218, 225, 255, .851), 1px 2px 4px 0 rgba(194, 186, 201, .949);
    margin-top: 10px;
    gap: 10px;
    justify-content: space-between;
    margin-right: 0px;
}

.calender {
    font-size: 16px;
    font-weight: 500;

    svg {
        margin-top: -3px;
        color: var(--black);
    }
}

.calendertime {
    font-size: 16px;
    font-weight: 500;
    margin-left: -12px;

    svg {
        margin-top: -3px;
    }
}

.timebody {
    text-align: center;
    cursor: pointer;

    &:hover {
        background: var(--green);
        color: var(--white);
    }

    .active {
        background: var(--green) !important;
        color: var(--white) !important;
    }
}

.timeactive {
    background: var(--green) !important;
    color: var(--white) !important;
}

.react-calendar__month-view__days {
    gap: 5px !important;
}

.react-calendar__tile {
    background-color: var(--black) !important;
    color: var(--white) !important;
    border-radius: 5px;
}

.react-calendar__tile:disabled {
    background-color: #f0f0f0 !important;
    color: var(--black) !important;
}

.react-calendar__tile--active {
    background-color: var(--green) !important;
}

.btnpopup {
    background-color: var(--black);
    color: var(--white);
    border: none;
    width: 100%;

    @include md {
        width: 50%;
        margin: auto;
    }

    &:hover,
    &:focus {
        background-color: var(--black);
        color: var(--white);
        border: none;
        box-shadow: unset !important;
    }
}

.talkfaq {
    .accordion-button:not(.collapsed) {
        color: var(--black) !important;
        background-color: var(--white) !important;
        box-shadow: 0 2px 6px 0 rgba(218, 225, 255, .851), 1px 2px 4px 0 rgba(194, 186, 201, .949);
    }

    .accordion-item {
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, .125);
        margin-top: 10px;
    }

    .accordion-item:not(:first-of-type) {
        border-top: 1px solid rgba(0, 0, 0, 0.125);
    }
}

// End Start
.checkterm {
    margin-left: 10px;
    font-size: 13px;

    a {
        text-decoration: none;
    }
}

// Step
.step {
    position: relative;
    list-style: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-right: calc(1.5rem / -2);
    margin-left: calc(1.5rem / -2)
}

.step.step-dashed .step-icon::after {
    border-left-style: dashed
}

.step-icon-border {
    border: .125rem solid rgba(0, 34, 28, .1)
}

.step-title {
    display: block;
    color: #00221c;
    font-weight: 600
}

.step-text:last-child {
    color: #51596c;
    margin-bottom: 0
}

.step-border-last-0 .step-item:last-child .step-icon::after {
    display: none
}

.step .step-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: .75rem;
    padding-left: .75rem;
    margin-bottom: 2.25rem
}

.step-item-between .step-item:last-child {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto
}

.step .step-content-wrapper {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    width: 100%
}

.step .step-content {
    -ms-flex: 1;
    flex: 1
}

.step-item.collapse:not(.show) {
    display: none
}

.step-item .step-title-description {
    display: none
}

.step-item.focus .step-title-description {
    display: block
}

.step .step-avatar {
    font-size: 1rem;
    font-weight: 600;
    width: 2.875rem;
    height: 2.875rem;
    border-radius: 50%;
    margin-right: 1rem
}

.step .step-avatar-img {
    max-width: 100%;
    height: auto;
    border-radius: 50%
}

.step .step-avatar::after {
    position: absolute;
    top: 3.625rem;
    left: 1.4375rem;
    height: calc(100% - 2.125rem);
    border-left: .125rem solid rgba(0, 34, 28, .1);
    content: ""
}

.step-avatar-xs .step-avatar,
.step-avatar-xs.step-avatar {
    font-size: .75rem;
    width: 1.75rem;
    height: 1.75rem
}

.step-avatar-xs .step-avatar::after,
.step-avatar-xs.step-avatar::after {
    top: 2.5rem;
    left: .8125rem;
    width: 1.625rem;
    height: calc(100% - 1rem)
}

.step-avatar-xs .step-divider::after {
    left: .875rem
}

.step-avatar-sm .step-avatar,
.step-avatar-sm.step-avatar {
    font-size: .8125rem;
    width: 2.3125rem;
    height: 2.3125rem
}

.step-avatar-sm .step-avatar::after,
.step-avatar-sm.step-avatar::after {
    top: 3.0625rem;
    left: 1.09375rem;
    width: 1.625rem;
    height: calc(100% - 1.5625rem)
}

.step-avatar-sm .step-divider::after {
    left: 1.15625rem
}

.step-avatar-lg .step-avatar,
.step-avatar-lg.step-avatar {
    font-size: 1.125rem;
    width: 5.5rem;
    height: 5.5rem
}

.step-avatar-lg .step-avatar::after,
.step-avatar-lg.step-avatar::after {
    top: 6.25rem;
    left: 2.6875rem;
    width: 1.625rem;
    height: calc(100% - 4.75rem)
}

.step-avatar-lg .step-divider::after {
    left: 2.75rem
}

.step-divider {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    text-transform: uppercase;
    height: 1rem;
    font-size: .8125rem;
    font-weight: 600
}

.step-divider::after {
    position: absolute;
    top: 1.75rem;
    left: 1.4375rem;
    height: calc(100% - .25rem);
    border-left: .125rem solid rgba(0, 34, 28, .1);
    content: ""
}

.step .step-icon {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    width: 2.875rem;
    height: 2.875rem;
    border-radius: 50%;
    margin-right: 1rem
}

.step .step-icon::after {
    position: absolute;
    top: 3.625rem;
    left: 1.4375rem;
    height: calc(100% - 2.125rem);
    border-left: .125rem solid rgba(0, 34, 28, .1);
    content: ""
}

.step .step-icon-pseudo::before {
    display: block;
    width: .25rem;
    height: .25rem;
    background-color: #97a4af;
    border-radius: 50%;
    content: ""
}

.step-icon-xs .step-icon,
.step-icon-xs.step-icon {
    font-size: .75rem;
    width: 1.75rem;
    height: 1.75rem
}

.step-icon-xs .step-icon::after,
.step-icon-xs.step-icon::after {
    top: 2.5rem;
    left: .8125rem;
    width: 1.625rem;
    height: calc(100% - 1rem)
}

.step-icon-xs .step-divider::after {
    left: .875rem
}

.step-icon-sm .step-icon,
.step-icon-sm.step-icon {
    font-size: .8125rem;
    width: 2.3125rem;
    height: 2.3125rem
}

.step-icon-sm .step-icon::after,
.step-icon-sm.step-icon::after {
    top: 3.0625rem;
    left: 1.09375rem;
    width: 1.625rem;
    height: calc(100% - 1.5625rem)
}

.step-icon-sm .step-divider::after {
    left: 1.15625rem
}

.step-icon-lg .step-icon,
.step-icon-lg.step-icon {
    font-size: 1.125rem;
    width: 5.5rem;
    height: 5.5rem
}

.step-icon-lg .step-icon::after,
.step-icon-lg.step-icon::after {
    top: 6.25rem;
    left: 2.6875rem;
    width: 1.625rem;
    height: calc(100% - 4.75rem)
}

.step-icon-lg .step-divider::after {
    left: 2.75rem
}

@media (min-width: 576px) {
    .step-sm.step-dashed .step-icon::after {
        border-left: none;
        border-top-style: dashed
    }

    .step-sm .step-item {
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex: 1;
        flex: 1;
        margin-bottom: 0
    }

    .step-sm:not(.step-inline) .step-content-wrapper {
        display: block
    }

    .step-sm .step-icon {
        margin-bottom: 1rem
    }

    .step-sm .step-icon::after {
        top: 1.4375rem;
        left: 4.375rem;
        width: calc(100% - 4.375rem);
        height: 1.625rem;
        border-top: .125rem solid rgba(0, 34, 28, .1);
        border-left: none
    }

    .step-sm .step-icon.step-icon-xs::after,
    .step-sm.step-icon-xs .step-icon::after {
        top: .875rem;
        left: 3.25rem;
        width: calc(100% - 3.25rem)
    }

    .step-sm .step-icon.step-icon-sm::after,
    .step-sm.step-icon-sm .step-icon::after {
        top: 1.15625rem;
        left: 3.8125rem;
        width: calc(100% - 3.8125rem)
    }

    .step-sm .step-icon.step-icon-lg::after,
    .step-sm.step-icon-lg .step-icon::after {
        top: 2.75rem;
        left: 7rem;
        width: calc(100% - 7rem)
    }
}

@media (min-width: 768px) {
    .step-md.step-dashed .step-icon::after {
        border-left: none;
        border-top-style: dashed
    }

    .step-md .step-item {
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex: 1;
        flex: 1;
        margin-bottom: 0
    }

    .step-md:not(.step-inline) .step-content-wrapper {
        display: block
    }

    .step-md .step-icon {
        margin-bottom: 1rem
    }

    .step-md .step-icon::after {
        top: 1.4375rem;
        left: 4.375rem;
        width: calc(100% - 4.375rem);
        height: 1.625rem;
        border-top: .125rem solid rgba(0, 34, 28, .1);
        border-left: none
    }

    .step-md .step-icon.step-icon-xs::after,
    .step-md.step-icon-xs .step-icon::after {
        top: .875rem;
        left: 3.25rem;
        width: calc(100% - 3.25rem)
    }

    .step-md .step-icon.step-icon-sm::after,
    .step-md.step-icon-sm .step-icon::after {
        top: 1.15625rem;
        left: 3.8125rem;
        width: calc(100% - 3.8125rem)
    }

    .step-md .step-icon.step-icon-lg::after,
    .step-md.step-icon-lg .step-icon::after {
        top: 2.75rem;
        left: 7rem;
        width: calc(100% - 7rem)
    }
}

@media (min-width: 992px) {
    .step-lg.step-dashed .step-icon::after {
        border-left: none;
        border-top-style: dashed
    }

    .step-lg .step-item {
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex: 1;
        flex: 1;
        margin-bottom: 0
    }

    .step-lg:not(.step-inline) .step-content-wrapper {
        display: block
    }

    .step-lg .step-icon {
        margin-bottom: 1rem
    }

    .step-lg .step-icon::after {
        top: 1.4375rem;
        left: 4.375rem;
        width: calc(100% - 4.375rem);
        height: 1.625rem;
        border-top: .125rem solid rgba(0, 34, 28, .1);
        border-left: none
    }

    .step-lg .step-icon.step-icon-xs::after,
    .step-lg.step-icon-xs .step-icon::after {
        top: .875rem;
        left: 3.25rem;
        width: calc(100% - 3.25rem)
    }

    .step-lg .step-icon.step-icon-sm::after,
    .step-lg.step-icon-sm .step-icon::after {
        top: 1.15625rem;
        left: 3.8125rem;
        width: calc(100% - 3.8125rem)
    }

    .step-lg .step-icon.step-icon-lg::after,
    .step-lg.step-icon-lg .step-icon::after {
        top: 2.75rem;
        left: 7rem;
        width: calc(100% - 7rem)
    }
}

@media (min-width: 1200px) {
    .step-xl.step-dashed .step-icon::after {
        border-left: none;
        border-top-style: dashed
    }

    .step-xl .step-item {
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex: 1;
        flex: 1;
        margin-bottom: 0
    }

    .step-xl:not(.step-inline) .step-content-wrapper {
        display: block
    }

    .step-xl .step-icon {
        margin-bottom: 1rem
    }

    .step-xl .step-icon::after {
        top: 1.4375rem;
        left: 4.375rem;
        width: calc(100% - 4.375rem);
        height: 1.625rem;
        border-top: .125rem solid rgba(0, 34, 28, .1);
        border-left: none
    }

    .step-xl .step-icon.step-icon-xs::after,
    .step-xl.step-icon-xs .step-icon::after {
        top: .875rem;
        left: 3.25rem;
        width: calc(100% - 3.25rem)
    }

    .step-xl .step-icon.step-icon-sm::after,
    .step-xl.step-icon-sm .step-icon::after {
        top: 1.15625rem;
        left: 3.8125rem;
        width: calc(100% - 3.8125rem)
    }

    .step-xl .step-icon.step-icon-lg::after,
    .step-xl.step-icon-lg .step-icon::after {
        top: 2.75rem;
        left: 7rem;
        width: calc(100% - 7rem)
    }
}

@media (min-width: 576px) {
    .step-sm.step-centered {
        text-align: center
    }

    .step-sm.step-centered .step-item:last-child .step-icon::after {
        display: none
    }

    .step-sm.step-centered .step-icon {
        margin-left: auto;
        margin-right: auto
    }

    .step-sm.step-centered .step-icon::after {
        width: calc(100% - 4.375rem);
        left: calc(50% + 2.9375rem)
    }

    .step-sm.step-centered .step-icon.step-icon-xs::after,
    .step-sm.step-centered.step-icon-xs .step-icon::after {
        width: calc(100% - 3.25rem);
        left: calc(50% + 2.375rem)
    }

    .step-sm.step-centered .step-icon.step-icon-sm::after,
    .step-sm.step-centered.step-icon-sm .step-icon::after {
        width: calc(100% - 3.8125rem);
        left: calc(50% + 2.65625rem)
    }

    .step-sm.step-centered .step-icon.step-icon-lg::after,
    .step-sm.step-centered.step-icon-lg .step-icon::after {
        width: calc(100% - 7rem);
        left: calc(50% + 4.25rem)
    }
}

@media (min-width: 768px) {
    .step-md.step-centered {
        text-align: center
    }

    .step-md.step-centered .step-item:last-child .step-icon::after {
        display: none
    }

    .step-md.step-centered .step-icon {
        margin-left: auto;
        margin-right: auto
    }

    .step-md.step-centered .step-icon::after {
        width: calc(100% - 4.375rem);
        left: calc(50% + 2.9375rem)
    }

    .step-md.step-centered .step-icon.step-icon-xs::after,
    .step-md.step-centered.step-icon-xs .step-icon::after {
        width: calc(100% - 3.25rem);
        left: calc(50% + 2.375rem)
    }

    .step-md.step-centered .step-icon.step-icon-sm::after,
    .step-md.step-centered.step-icon-sm .step-icon::after {
        width: calc(100% - 3.8125rem);
        left: calc(50% + 2.65625rem)
    }

    .step-md.step-centered .step-icon.step-icon-lg::after,
    .step-md.step-centered.step-icon-lg .step-icon::after {
        width: calc(100% - 7rem);
        left: calc(50% + 4.25rem)
    }
}

@media (min-width: 992px) {
    .step-lg.step-centered {
        text-align: center
    }

    .step-lg.step-centered .step-item:last-child .step-icon::after {
        display: none
    }

    .step-lg.step-centered .step-icon {
        margin-left: auto;
        margin-right: auto
    }

    .step-lg.step-centered .step-icon::after {
        width: calc(100% - 4.375rem);
        left: calc(50% + 2.9375rem)
    }

    .step-lg.step-centered .step-icon.step-icon-xs::after,
    .step-lg.step-centered.step-icon-xs .step-icon::after {
        width: calc(100% - 3.25rem);
        left: calc(50% + 2.375rem)
    }

    .step-lg.step-centered .step-icon.step-icon-sm::after,
    .step-lg.step-centered.step-icon-sm .step-icon::after {
        width: calc(100% - 3.8125rem);
        left: calc(50% + 2.65625rem)
    }

    .step-lg.step-centered .step-icon.step-icon-lg::after,
    .step-lg.step-centered.step-icon-lg .step-icon::after {
        width: calc(100% - 7rem);
        left: calc(50% + 4.25rem)
    }
}

@media (min-width: 992px) {
    .step-lg.step-centered {
        text-align: center
    }

    .step-lg.step-centered .step-item:last-child .step-icon::after {
        display: none
    }

    .step-lg.step-centered .step-icon {
        margin-left: auto;
        margin-right: auto
    }

    .step-lg.step-centered .step-icon::after {
        width: calc(100% - 4.375rem);
        left: calc(50% + 2.9375rem)
    }

    .step-lg.step-centered .step-icon.step-icon-xs::after,
    .step-lg.step-centered.step-icon-xs .step-icon::after {
        width: calc(100% - 3.25rem);
        left: calc(50% + 2.375rem)
    }

    .step-lg.step-centered .step-icon.step-icon-sm::after,
    .step-lg.step-centered.step-icon-sm .step-icon::after {
        width: calc(100% - 3.8125rem);
        left: calc(50% + 2.65625rem)
    }

    .step-lg.step-centered .step-icon.step-icon-lg::after,
    .step-lg.step-centered.step-icon-lg .step-icon::after {
        width: calc(100% - 7rem);
        left: calc(50% + 4.25rem)
    }
}

.step .step-is-invalid-icon,
.step .step-is-valid-icon {
    display: none
}

.step .active .step-icon,
.step .active.is-valid .step-icon {
    color: #fff;
    background-color: #008060
}

.step .active .step-title,
.step .active.is-valid .step-title {
    color: #008060
}

.step .is-valid .step-icon {
    color: #fff;
    background-color: #008060
}

.step .is-valid .step-title {
    color: #008060
}

.step .is-valid .step-is-valid-icon {
    display: -ms-inline-flexbox;
    display: inline-flex
}

.step .is-valid .step-is-default-icon,
.step .is-valid .step-is-invalid-icon {
    display: none
}

.step .is-invalid .step-icon {
    color: #fff;
    background-color: #692340
}

.step .is-invalid .step-title {
    color: #692340
}

.step .is-invalid .step-is-invalid-icon {
    display: -ms-inline-flexbox;
    display: inline-flex
}

.step .is-invalid .step-is-default-icon,
.step .is-invalid .step-is-valid-icon {
    display: none
}

.step-icon-primary {
    color: #fff;
    background-color: #008060
}

.step-icon-primary.step-icon-pseudo::before {
    background-color: #fff
}

.step-icon-secondary {
    color: #fff;
    background-color: #51596c
}

.step-icon-secondary.step-icon-pseudo::before {
    background-color: #fff
}

.step-icon-success {
    color: #fff;
    background-color: #077c76
}

.step-icon-success.step-icon-pseudo::before {
    background-color: #fff
}

.step-icon-info {
    color: #fff;
    background-color: #334ac0
}

.step-icon-info.step-icon-pseudo::before {
    background-color: #fff
}

.step-icon-warning {
    color: #000;
    background-color: #f1b980
}

.step-icon-warning.step-icon-pseudo::before {
    background-color: #000
}

.step-icon-danger {
    color: #fff;
    background-color: #692340
}

.step-icon-danger.step-icon-pseudo::before {
    background-color: #fff
}

.step-icon-light {
    color: #000;
    background-color: #f9fafc
}

.step-icon-light.step-icon-pseudo::before {
    background-color: #000
}

.step-icon-dark {
    color: #fff;
    background-color: #00221c
}

.step-icon-dark.step-icon-pseudo::before {
    background-color: #fff
}

.step-icon-soft-primary {
    color: #008060;
    background-color: rgba(0, 128, 96, .1)
}

.step-icon-soft-primary.step-icon-pseudo::before {
    background-color: #008060
}

.step-icon-soft-secondary {
    color: #51596c;
    background-color: rgba(81, 89, 108, .1)
}

.step-icon-soft-secondary.step-icon-pseudo::before {
    background-color: #51596c
}

.step-icon-soft-success {
    color: #077c76;
    background-color: rgba(7, 124, 118, .1)
}

.step-icon-soft-success.step-icon-pseudo::before {
    background-color: #077c76
}

.step-icon-soft-info {
    color: #334ac0;
    background-color: rgba(51, 74, 192, .1)
}

.step-icon-soft-info.step-icon-pseudo::before {
    background-color: #334ac0
}

.step-icon-soft-warning {
    color: #f1b980;
    background-color: rgba(241, 185, 128, .1)
}

.step-icon-soft-warning.step-icon-pseudo::before {
    background-color: #f1b980
}

.step-icon-soft-danger {
    color: #692340;
    background-color: rgba(105, 35, 64, .1)
}

.step-icon-soft-danger.step-icon-pseudo::before {
    background-color: #692340
}

.step-icon-soft-light {
    color: #f9fafc;
    background-color: rgba(249, 250, 252, .1)
}

.step-icon-soft-light.step-icon-pseudo::before {
    background-color: #f9fafc
}

.step-icon-soft-dark {
    color: #00221c;
    background-color: rgba(0, 34, 28, .1)
}

.step-icon-soft-dark.step-icon-pseudo::before {
    background-color: #00221c
}

.step-inline .step-content-wrapper {
    -ms-flex-align: center;
    align-items: center
}

.step-inline .step-item:last-child .step-title::after {
    display: none
}

.step-inline .step-title {
    display: inline-block
}

.alltabs {
    overflow: auto !important;
}

.videosec {
    iframe {
        width: 100%;
        height: 215px !important;

        @include md {
            width: 100% !important;
            height: 375px !important;
        }
    }
}

.steps p {
    border-bottom: 1px solid #000;
    color: #59702f;
    font-size: 17px !important;
    font-weight: 600 !important;
    padding: 0px 0px 0px 0px !important;
    letter-spacing: .5px !important;
    text-align: unset !important;
}

.pdetailnew {
    table {
        margin-bottom: 10px;

        tbody {
            border: 1px solid #b1b1b1;
        }

        tbody,
        td,
        tfoot,
        th,
        thead,
        tr {
            border: 1px solid;
            border-color: #e8e8e8;
            text-align: left;
        }

        tbody,
        td,
        tfoot,
        th,
        thead,
        tr {
            border: 1px solid;
            border-color: #e8e8e8;
            text-align: left;
        }
    }
}

.lpproductdetail {
    ul {
        padding-left: 2rem !important;
        list-style: disc !important;

        li {
            margin: 5px;
        }
    }
}

.newlp {
    p {
        text-align: left !important;

        @include md {
            text-align: center !important;
        }
    }

    h4 {
        text-align: left !important;
        font-weight: 600 !important;

        @include md {
            text-align: center !important;
            font-weight: 600 !important;
        }
    }

    .wn-flex {
        display: unset !important;

        @include md {
            display: flex !important;
        }
    }
}

.newlpp {
    text-align: left !important;

    @include md {
        text-align: center !important;
    }
}
.section-all .key-ingredients .ing-box-text p {
    font-size: 14px !important;
    color: var(--black);
    line-height: 1.3 !important;
    margin: 0;
    padding-top: 5px;
    text-align: left !important;
}
.section-all .key-ingredients .ing-box {
    border-radius: 50px;
    background: #e1e5ee;
    padding: 40px;
}
.key-ingredients{
    img{
        width: 100% !important;
    }
}
.newlpps{
    text-align: left !important;
}
.newlp {
    .key-benefit {
        .use-card {
            height: 280px !important;

            @include md {
                height: 300px !important;
            }

            p {
                font-size: 14px !important;
            }
        }
    }
}
.lpproductdetails{
    p{
        text-align: left !important;
    }
}
.disabledBtn {
    background-color: #d6d6d6;
    color: #9e9e9e;
    cursor: not-allowed;
}
.callbtn{
    background: var(--black);
    border: none;
    color: var(--white);
    border-radius: 5px;
    margin-top: 5px;
    text-align: center;
    a{
        color: #fff;
        font-size: 15px !important;
        &:hover{
            color: #fff !important;
        }
    }
}