:root {
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  --body-color: #f4f4f4;
  --black: #04152d;
  --black2: #041226;
  --black3: #020c1b;
  --black-lighter: #1c4b91;
  --black-light: #173d77;
  --pink: #da2f68;
  --orange: #ff5733;
  --orange2: #ff9f00;
  --green: #0e870e;
  --white: #fff;
  --green1: #51e38f;
  --text-gray: #aaa9ad;
  --gradient: linear-gradient(98.37deg, #f89e00 0.99%, #da2f68 100%);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-display: swap;
  font-display: auto;
  font-display: block;
  font-display: fallback;
  font-display: optional;
}


ul {
  list-style: none;
  padding-left: unset !important;
}

li a {
  text-decoration: none;
  color: #fff;
}
li p a {
  text-decoration: unset;
  color: blue !important;
}
.catdesps{
  ul{
    list-style: unset;
  }
}
body {
  background-color: var(--body-color);
  font-family: 'Inter', sans-serif !important;
}

.skeleton {
  position: relative;
  overflow: hidden;
  background-color: #0a2955;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg,
        rgba(#193763, 0) 0,
        rgba(#193763, 0.2) 20%,
        rgba(#193763, 0.5) 60%,
        rgba(#193763, 0));
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.carousel .thumbs-wrapper {
  margin: unset !important;
  overflow: hidden;
}
h1, h2, h3, h4, h5, h6 {
  color: var(--black);
  font-family: 'Inter', sans-serif !important;
  letter-spacing: 0.6px !important;
  line-height: 1.74 !important;
}
h1,h2,h3{
  font-size: 1rem !important;
}
h4 {
  color: var(--black);
  font-family: 'Inter', sans-serif !important;
  font-weight: 400;
  font-size: 1rem !important;
}

.addToCartBtn {
  background: var(--white);
  color: var(--black);
  border: 1px solid var(--black);
  width: 48%;
  text-align: center;
  border-radius: 10px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;

  &:hover {
    background: var(--black);
    color: var(--white);
    border: 1px solid var(--black);
  }
}

.addPToCartBtn {
  background: var(--white);
  color: var(--black);
  border: 1px solid var(--black);
  width: 48%;
  text-align: center;
  padding: 5px 15px;
  border-radius: 10px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;

  &:hover {
    background: var(--black);
    color: var(--white);
    border: 1px solid var(--black);
  }
}

.buyItNowBtn {
  background-color: var(--orange);
  color: var(--white);
  width: 48%;
  text-align: center;
  padding: 8px 0px;
  border-radius: 10px;
  border: 1px solid var(--orange);
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;

  &:hover {
    background: var(--white);
    color: var(--black);
    border: 1px solid var(--black);
  }
}

.buyPItNowBtn {
  background-color: var(--orange);
  color: var(--white);
  width: 48%;
  text-align: center;
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid var(--orange);
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;

  &:hover {
    background: var(--white);
    color: var(--black);
    border: 1px solid var(--black);
  }
}

p {
  font-family: 'Inter', sans-serif !important;
  letter-spacing: 0.6px !important;
  font-weight: 400 !important;
  line-height: 1.74 !important;
  font-size: 15px !important;
  font-style: normal;
  visibility: visible;
  color: #696969;
  text-align: justify;
}

.appContainer {
  max-width: 900px;
  text-align: center;
  position: relative;
  margin: auto;
}

.breadcrumbs {
  background-color: #f5f5f5;
  margin: 0px 0 10px 0;
  padding: 5px 0;

  .items {
    font-size: 1.2rem;
    color: #a3a3a3;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 0px;
    list-style: none none;

    .items>li {
      display: inline-block;
      vertical-align: top;
    }

    .item:not(:last-child) {
      display: inline-block;
      text-decoration: none;
    }

    .item:not(:last-child):after {
      content: "/";
      margin: 0 3px;
    }

    a {
      color: #666;
      text-decoration: none;
    }
  }
}

.accordion {
  border: unset !important;
  margin-bottom: 20px;
}

.accordion__button {
  background-color: var(--white) !important;
  color: var(--black) !important;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  box-shadow: 0 2px 6px 0 rgba(218,225,255,.85), 0 5px 8px 0 rgba(214,188,238,.95) !important;
}

.accordion__panel {
  background: #fff !important;
  padding: 5px 20px 0px 20px !important;
  box-shadow: 0 2px 6px 0 rgba(218,225,255,.85), 0 5px 8px 0 rgba(214,188,238,.95) !important;
}

.react-multi-carousel-list {
  button {
    z-index: 1 !important;
    letter-spacing: .6px;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
  }
}

.awssld__bullets button {
  padding: 0;
  display: block;
  width: 8px !important;
  height: 8px !important;
  margin: 5px;
  border-radius: 50%;
  background: var(--control-bullet-color);
  text-indent: -9999px;
  overflow: hidden;
  cursor: pointer;
  border: none;
  transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45), background-color 0.175s ease-out;
  margin-top: -40px !important;
}
.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px) !important;
}
.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px) !important;
}
.react-multiple-carousel__arrow {
  background: rgb(0 0 0 / 11%) !important;
}